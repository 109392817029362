import React, { useState, useEffect } from 'react';
import { OptionChipsContainer, OptionChip } from './style'; // Import styles

const PredictedQuestionChips = ({ questions, onQuestionClick, messages, setIsLoading, isLoading }) => {
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    if (!messages?.length) return;

    // Get current message id
    const currentMessageId = messages[messages.length - 1]?.id;

    // Find the latest prediction message
    const predictionMessages = messages.filter(msg => 
      msg.type === "predicted_questions" || msg.predicted_questions || msg.questions
    );
    const latestPredictionMessage = predictionMessages[predictionMessages.length - 1];

    // Only show if this component's questions belong to the latest prediction message
    if (questions && currentMessageId !== latestPredictionMessage?.id) {
      setShouldShow(false);
    }
  }, [messages, questions]);

  const handleClick = (question) => {
    setShouldShow(false);
    setIsLoading(true);
    onQuestionClick(question);
  };

  // Don't render if we shouldn't show or are loading
  if (!questions || !shouldShow || isLoading) {
    return null;
  }

  // Get only the last 3 questions
  const lastThreeQuestions = Object.entries(questions)
    .slice(-3)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  return (
    <OptionChipsContainer>
      {Object.values(lastThreeQuestions).map((question, index) => (
        <OptionChip key={index} onClick={() => handleClick(question)}>
          {question}
        </OptionChip>
      ))}
    </OptionChipsContainer>
  );
};

export default PredictedQuestionChips;