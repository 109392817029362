import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const ProgressiveImage = ({ src, alt, style, onLoad, onError }) => {
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  useEffect(() => {
    let isMounted = true;
    let objectURL = null;

    const loadImage = async () => {
      try {
        const response = await fetch(src);
        if (!response.ok) throw new Error('Network response was not ok');
        const contentLength = response.headers.get('Content-Length');
        let total = null;
        if (contentLength) {
          total = parseInt(contentLength, 10);
        } else {
          setIsIndeterminate(true);
        }
        let loaded = 0;

        const reader = response.body.getReader();
        const chunks = [];
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          chunks.push(value);
          loaded += value.length;
          if (isMounted && total) {
            setProgress(Math.round((loaded / total) * 100));
          }
        }

        // Combine chunks into a single Blob
        const blob = new Blob(chunks, { type: response.headers.get('Content-Type') || 'image/jpeg' });
        objectURL = URL.createObjectURL(blob);
        if (isMounted) {
          setImageDataUrl(objectURL);
          if (onLoad) onLoad();
        }
      } catch (error) {
        console.error('Error loading image:', error);
        if (onError) onError(error);
      }
    };

    loadImage();

    return () => {
      isMounted = false;
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [src, onLoad, onError]);

  return (
    <div style={{ position: 'relative', ...style }}>
      {imageDataUrl ? (
        <motion.img
          src={imageDataUrl}
          alt={alt}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#eee',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            {isIndeterminate ? (
              <span>Loading...</span>
            ) : (
              <span>{progress}%</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressiveImage;
