import { EmojiObjects, ExpandLess, ExpandMore, Warning } from "@mui/icons-material";
import { Box, Button, Grid, Typography, Alert } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";

const AccessorySection = ({ title, accessories = [], expanded, onToggle }) => {
  // Handle null, undefined, or empty accessories
  if (!accessories?.length) return null;

  // Function to split accessories into three columns
  const splitIntoColumns = (accessories) => {
    try {
      const columnLength = Math.ceil(accessories.length / 3);
      return [
        accessories.slice(0, columnLength),
        accessories.slice(columnLength, 2 * columnLength),
        accessories.slice(2 * columnLength),
      ];
    } catch (error) {
      console.error("Error splitting columns:", error);
      return [[], [], []];
    }
  };

  const columns = splitIntoColumns(accessories);
  const displayedColumns = expanded
    ? columns
    : columns.map((col) => col.slice(0, 3));

  return (
    <Box sx={{ mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          variant="h6"
          color="secondary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <EmojiObjects sx={{ mr: 1 }} /> {title}
        </Typography>
        <Button
          onClick={onToggle}
          endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          sx={{ 
            textTransform: "none", 
            borderRadius: "20px",
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          {expanded ? "Nascondi" : "Mostra tutto"}
        </Button>
      </Box>
      <Grid container spacing={3}>
        {displayedColumns.map((column, colIndex) => (
          <Grid item xs={12} md={4} key={colIndex}>
            {column.map((accessory, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{ 
                  py: 1, 
                  borderBottom: "1px solid #e0e0e0",
                  '&:last-child': {
                    borderBottom: 'none'
                  }
                }}
              >
                {accessory}
              </Typography>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const EmptyState = () => (
  <Box sx={{ 
    textAlign: 'center', 
    py: 4,
    px: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: 2
  }}>
    <EmojiObjects sx={{ fontSize: 48, color: 'action.disabled', mb: 2 }} />
    <Typography variant="h6" color="text.secondary" gutterBottom>
      Nessun accessorio disponibile
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Non sono presenti accessori per questo veicolo.
    </Typography>
  </Box>
);

const ErrorState = ({ error }) => (
  <Alert 
    severity="error" 
    sx={{ mb: 2 }}
    icon={<Warning />}
  >
    Si è verificato un errore nel caricamento degli accessori: {error.message}
  </Alert>
);

const Accessories = ({ standardAccessories = [], optionalAccessories = [] }) => {
  const [standardExpanded, setStandardExpanded] = useState(false);
  const [optionalExpanded, setOptionalExpanded] = useState(false);
  const [error, setError] = useState(null);

  try {
    // Check if both arrays are empty or undefined
    if ((!standardAccessories?.length && !optionalAccessories?.length) || 
        (standardAccessories === undefined && optionalAccessories === undefined)) {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <EmptyState />
        </motion.div>
      );
    }

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {error && <ErrorState error={error} />}
        
        <AccessorySection
          title="Dotazioni di serie"
          accessories={standardAccessories}
          expanded={standardExpanded}
          onToggle={() => setStandardExpanded(!standardExpanded)}
        />

        <AccessorySection
          title="Optional inclusi"
          accessories={optionalAccessories}
          expanded={optionalExpanded}
          onToggle={() => setOptionalExpanded(!optionalExpanded)}
        />
      </motion.div>
    );
  } catch (err) {
    setError(err);
    return <ErrorState error={err} />;
  }
};

export default Accessories;