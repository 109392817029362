import React from 'react';

// Add timestamp tracking at the top level
let lastEventTimestamp = 0;
let formShownCount = 0;  // Add counter for form shown events

// Event categories
export const CHAT_EVENTS = {
  INTERACTION: 'interaction',
  VEHICLE: 'vehicle',
  FORM: 'form',
  NAVIGATION: 'navigation'
};

// GTM event push helper
export const pushToGTM = (eventData, version = '1.0') => {
  const now = Date.now();
  
  // Enhanced duplicate prevention for form_shown events
  if (eventData.action === 'MIA_chat_form_shown') {
    if (now - lastEventTimestamp < 1000) { // Increase debounce to 1 second
      return;
    }
    formShownCount++;
    if (formShownCount > 1) { // Only allow one form_shown event
      return;
    }
  }
  
  lastEventTimestamp = now;

  const debugEvent = (eventData) => {
    const cleanData = {
      event: 'MIA_chat',
      version,
      ...eventData
    };
  };

  debugEvent(eventData);
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'MIA_chat',
      version,
      ...eventData
    });
  }
};

// Analytics event handlers
export const trackChatEvent = {
  // Chat core interactions
  sendMessage: (eventData) => {
    pushToGTM({
      event: 'asc_comm_submission',
      action: 'MIA_chat_send_message',
      event_owner: eventData.event_owner,
      page_type: eventData.page_type,
      comm_type: eventData.comm_type,
      affiliation: eventData.affiliation,
      element_position: eventData.element_position,
      department: eventData.department,
      affiliation_id: eventData.affiliation_id,
      comm_status: eventData.comm_status,
      message_length: eventData.message_length
    });
  },

  attachFile: (eventData) => {
    pushToGTM({
      event: 'asc_comm_submission',
      action: 'MIA_chat_attach_file',
      event_owner: eventData.event_owner,
      page_type: eventData.page_type,
      comm_type: eventData.comm_type,
      affiliation: eventData.affiliation,
      element_position: eventData.element_position,
      department: eventData.department,
      affiliation_id: eventData.affiliation_id,
      comm_status: eventData.comm_status,
      file_type: eventData.file_type
    });
  },

  toggleChat: (isOpen) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_toggle_chat',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: isOpen ? 'start' : 'close'
    });
  },

  // Vehicle interactions
  viewVehicle: (vehicleId, vehicleTitle) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_view_vehicle',
      event_owner: 'aitomotive',
      page_type: 'item',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      vehicle_id: vehicleId,
      vehicle_title: vehicleTitle
    });
  },

  bookVisit: (vehicleId, vehicleTitle) => {
    pushToGTM({
      event: 'asc_comm_submission',
      action: 'MIA_chat_book_visit',
      event_owner: 'aitomotive',
      page_type: 'item',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      department: 'sales',
      affiliation_id: 'bot',
      comm_status: 'appt_set',
      vehicle_id: vehicleId,
      vehicle_title: vehicleTitle
    });
  },

  // Wishlist interactions
  toggleWishlist: (vehicleId, isAdding) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: isAdding ? 'MIA_chat_add_to_wishlist' : 'MIA_chat_remove_from_wishlist',
      event_owner: 'aitomotive',
      page_type: 'item',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      vehicle_id: vehicleId,
      comm_status: isAdding ? 'engage' : 'close'
    });
  },

  openWishlist: () => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_open_wishlist',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage'
    });
  },

  toggleChatSize: (action) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_toggle_chat_size',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: action === 'large_size' ? 'engage' : 'close',
      size_action: action
    });
  },

  closeChat: () => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_close_chat',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'close'
    });
  },

  // Form interactions
  saveForm: (formType) => {
    pushToGTM({
      event: 'asc_comm_submission',
      action: 'MIA_chat_save_form',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'form',
      affiliation: 'native',
      element_position: 'bottom_right',
      department: 'sales',
      affiliation_id: 'bot',
      comm_status: 'crm_update',
      form_type: formType
    });
  },

  // Message tracking
  messageCount: (count) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_message_count',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      message_count: count,
      nonInteraction: true
    });
  },

  // Carousel navigation
  carouselNav: (direction) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_carousel_navigation',
      event_owner: 'aitomotive',
      page_type: 'itemlist',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      navigation_direction: direction
    });
  },

  // Vehicle page open
  openVehiclePage: (vehicleId, vehicleTitle) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_open_vehicle_page',
      event_owner: 'aitomotive',
      page_type: 'item',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      vehicle_id: vehicleId,
      vehicle_title: vehicleTitle
    });
  },

  viewVehicleDetails: (vehicleId, title) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_view_details',
      event_owner: 'aitomotive',
      page_type: 'item',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      vehicle_id: vehicleId,
      vehicle_title: title
    });
  },

  linkClick: (url, text) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_link_click',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      destination_url: url,
      link_text: text
    });
  },

  formInteraction: (formType, action, additionalData = {}) => {
    // Reset form shown count when form is closed
    if (action === 'form_closed') {
      formShownCount = 0;
    }

    const baseData = {
      event: 'asc_comm_submission',
      action: 'MIA_chat_' + action,
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'form',
      affiliation: 'native',
      element_position: 'bottom_right',
      department: 'sales',
      affiliation_id: 'bot'
    };

    // Set appropriate comm_status based on action
    let comm_status = 'engage';
    if (action === 'form_shown') comm_status = 'load';
    else if (action === 'form_submitted') comm_status = 'crm_update';
    else if (action === 'form_closed') comm_status = 'close';
    else if (action === 'form_submission_failed') comm_status = 'failed';

    pushToGTM({
      ...baseData,
      comm_status,
      form_type: formType,
      timestamp: new Date().toISOString(),
      ...additionalData
    });
  },

  carouselShown: (vehicleCount, vehicleInfo) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_carousel_shown',
      event_owner: 'aitomotive',
      page_type: 'itemlist',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'load',
      vehicle_count: vehicleCount,
      vehicles: vehicleInfo.map(vehicle => ({
        id: vehicle.id,
        title: vehicle.title,
        brand: vehicle.brand,
        usagetype: vehicle.usagetype,
        km: vehicle.km
      })),
      timestamp: new Date().toISOString()
    });
  },

  formProposed: (formType) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_form_proposed',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'form',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'load',
      form_type: formType,
      timestamp: new Date().toISOString()
    });
  },

  viewVehicleCarousel: (vehicleTitle) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_carousel_shown',
      event_owner: 'aitomotive',
      page_type: 'itemlist',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'load',
      vehicle_title: vehicleTitle || 'Vehicle Carousel',
      timestamp: new Date().toISOString()
    });
  },

  viewVehicleTab: (vehicleTitle, tabName) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_tab_view',
      event_owner: 'aitomotive',
      page_type: 'item',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      vehicle_title: vehicleTitle,
      tab_name: tabName,
      timestamp: new Date().toISOString()
    });
  },

  // Button interaction tracking
  buttonClick: (buttonName, category = 'Button Interaction') => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_button_click',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      button_name: buttonName,
      interaction_category: category,
      timestamp: new Date().toISOString()
    });
  },

  // Search interactions
  searchSubmit: (query) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_search_submit',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      search_query: query,
      timestamp: new Date().toISOString()
    });
  },

  searchSuggestionClick: (suggestion) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_suggestion_click',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      suggestion_text: suggestion,
      timestamp: new Date().toISOString()
    });
  },

  // Form interactions
  configFormSubmit: (brand, budget) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_config_submit',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'form',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      brand: brand || 'No Brand',
      budget: budget || 'No Budget',
      timestamp: new Date().toISOString()
    });
  },

  brandSelect: (brand) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_brand_select',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'form',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      selected_brand: brand,
      timestamp: new Date().toISOString()
    });
  },

  budgetSelect: (budget) => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_budget_select',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'form',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'engage',
      selected_budget: budget,
      timestamp: new Date().toISOString()
    });
  },

  minimizeChat: () => {
    pushToGTM({
      event: 'asc_comm_engagement',
      action: 'MIA_chat_minimize',
      event_owner: 'aitomotive',
      page_type: 'home',
      comm_type: 'chat',
      affiliation: 'native',
      element_position: 'bottom_right',
      comm_status: 'close',
      timestamp: new Date().toISOString()
    });
  }
};