import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  LinearProgress,
  useTheme,
} from "@mui/material";

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Help as HelpIcon,
  Lock as LockIcon,
  Campaign as CampaignIcon,
  Shield as ShieldIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchRegistrationForm, submitFormResponse } from "../../api/api";
import { trackChatEvent } from "../../utils/analytics";

const PersonalDataForm = ({ onSubmit, onSendMessage, setIsLoading }) => {
  const [formData, setFormData] = useState(null);
  const [formState, setFormState] = useState({});
  const [originalFormState, setOriginalFormState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formDisplayCount, setFormDisplayCount] = useState(1);
  const [hasChanges, setHasChanges] = useState(false);
  const theme = useTheme();
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [formAnimation, setFormAnimation] = useState(false);
  const [fieldTransition, setFieldTransition] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const response = await fetchRegistrationForm();
      setFormData(response.data);
      initializeFormState(response.data);
    } catch (err) {
      setError("Impossibile recuperare i dati del modulo. Riprova più tardi.");
    }

    const savedFormState = localStorage.getItem("formState");
    const savedIsSubmitted = localStorage.getItem("isSubmitted");

    if (savedFormState) {
      const parsedState = JSON.parse(savedFormState);
      setFormState(parsedState);
      setOriginalFormState(parsedState);
    }
    if (savedIsSubmitted) {
      setIsSubmitted(JSON.parse(savedIsSubmitted));
      setIsEditing(false);
    }

    // Tracking form display count
    const storedCount = parseInt(localStorage.getItem("form_display_count") || "1", 10);
    setFormDisplayCount(storedCount);
    trackChatEvent.formInteraction("personal_data", "form_shown", {
      display_count: storedCount,
    });
    localStorage.setItem("form_display_count", (storedCount + 1).toString());
  };

  const initializeFormState = (data) => {
    const savedFormState = localStorage.getItem("formState");
    if (savedFormState) {
      const parsedState = JSON.parse(savedFormState);
      setFormState(parsedState);
      setIsSubmitted(!!parsedState.id);
      setIsEditing(!parsedState.id);
    } else {
      const initialState = {};
      data.pages.forEach((page) => {
        page.groups.forEach((group) => {
          group.fields
            .filter(field => !field.is_hidden)
            .forEach((field) => {
              if (field.field_type === "checkbox") {
                initialState[field.field_id] = [];
              } else {
                initialState[field.field_id] = "";
              }
            });
        });
      });
      setFormState(initialState);
      localStorage.setItem("formState", JSON.stringify(initialState));
    }
  };

  const handleChange = (fieldId, value, fieldType) => {
    setFormState((prevState) => {
      let newState;
      if (fieldType === "checkbox") {
        const currentValues = prevState[fieldId] || [];
        const newValues = currentValues.includes(value)
          ? currentValues.filter((v) => v !== value)
          : [...currentValues, value];
        newState = { ...prevState, [fieldId]: newValues };
      } else {
        newState = { ...prevState, [fieldId]: value };
        
        // If this is a phone field change, update the email field if it's hidden
        const emailField = formData?.pages.flatMap(page => 
          page.groups.flatMap(group => 
            group.fields.find(field => field.keyword === "email")
          )
        ).filter(Boolean)[0];
        
        const phoneField = formData?.pages.flatMap(page => 
          page.groups.flatMap(group => 
            group.fields.find(field => field.keyword === "phone")
          )
        ).filter(Boolean)[0];

        if (emailField?.is_hidden && phoneField?.field_id === fieldId) {
          newState[emailField.field_id] = value;
        }
      }
      
      setHasChanges(JSON.stringify(newState) !== JSON.stringify(originalFormState));
      localStorage.setItem("formState", JSON.stringify(newState));
      return newState;
    });
  };

  const evaluateCondition = useCallback(
    (conditionsArray) => {
      const evaluateSingleCondition = (condition) => {
        const { fieldId, type, value } = condition;
        const fieldValue = formState[fieldId];

        if (Array.isArray(fieldValue)) {
          // For checkbox fields
          switch (type) {
            case "equals":
              return (
                JSON.stringify(fieldValue.sort()) ===
                JSON.stringify(
                  value
                    .split(",")
                    .map((v) => v.trim())
                    .sort()
                )
              );
            case "includes":
              return fieldValue.includes(value);
            case "not_includes":
              return !fieldValue.includes(value);
            default:
              return false;
          }
        } else {
          // For other fields
          switch (type) {
            case "equals":
              return fieldValue === value;
            case "not_equals":
              return fieldValue !== value;
            case "contains":
              return String(fieldValue).includes(value);
            case "not_contains":
              return !String(fieldValue).includes(value);
            case "greater_than":
              return Number(fieldValue) > Number(value);
            case "less_than":
              return Number(fieldValue) < Number(value);
            default:
              return true;
          }
        }
      };

      let result = true;
      let currentOperator = "AND";

      for (let i = 0; i < conditionsArray.length; i++) {
        const condition = conditionsArray[i];
        const conditionResult = evaluateSingleCondition(condition);

        if (currentOperator === "AND") {
          result = result && conditionResult;
        } else {
          result = result || conditionResult;
        }

        currentOperator = condition.logicalOperator || "AND";

        if (currentOperator === "AND" && !result) break;
        if (currentOperator === "OR" && result) break;
      }

      return result;
    },
    [formState]
  );

  const evaluateFieldConditions = useCallback(
    (conditions) => {
      if (!conditions || conditions === "[]" || conditions === null) return true;
      try {
        const parsedConditions = JSON.parse(conditions);
        return evaluateCondition(parsedConditions);
      } catch (error) {
        console.error("Error parsing field conditions:", error);
        return true;
      }
    },
    [evaluateCondition]
  );

  const visibleFields = useMemo(() => {
    if (!formData || !formData.pages) return [];
    return formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields.filter((field) => 
          field && 
          !field.is_hidden &&
          evaluateFieldConditions(field?.conditions)
        )
      )
    );
  }, [formData, evaluateFieldConditions]);

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = useCallback(() => {
    if (!formData) return false;

    const requiredFields = formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields
          .filter(field => !field.is_hidden)
          .filter(
            (field) => field.required && evaluateFieldConditions(field.conditions)
          )
      )
    );

    const isValid = requiredFields.every((field) => {
      const value = formState[field.field_id];
      return value !== undefined && value !== "" && value.length !== 0;
    });

    setIsFormValid(isValid);
    return isValid;
  }, [formData, formState, evaluateFieldConditions]);

  useEffect(() => {
    validateForm();
  }, [formState, validateForm]);

  const calculateProgress = () => {
    if (!formData) return 0;
    const allFields = formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields
          .filter(field => !field.is_hidden)
          .filter((field) => evaluateFieldConditions(field.conditions))
      )
    );
    const filledCount = allFields.filter((field) => {
      const val = formState[field.field_id];
      return val && val.length !== 0;
    }).length;
    return (filledCount / allFields.length) * 100;
  };

  const progress = calculateProgress();

  const renderDescription = (description, linkType) => {
    if (!description) return null;

    const parts = description.split(/(https?:\/\/\S+)/gi);
    return parts.map((part, index) => {
      if (part.match(/(https?:\/\/\S+)/gi)) {
        const linkStyle = {
          fontWeight: "bold",
          textDecoration: "underline",
          display: "inline-flex",
          alignItems: "center",
          gap: "4px",
          ...(linkType === "gdpr"
            ? { color: theme.palette.info.main }
            : { color: theme.palette.secondary.main }),
        };

        const icon =
          linkType === "gdpr" ? (
            <LockIcon fontSize="small" />
          ) : (
            <CampaignIcon fontSize="small" />
          );

        return (
          <Link
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            sx={linkStyle}
          >
            {icon}
            {linkType === "gdpr" ? "Link Privacy Policy" : "Link "}
          </Link>
        );
      }
      return part;
    });
  };

  const renderField = (field) => {
    const value = formState[field.field_id] || "";
    const disabled = !isEditing;

    const commonProps = {
      fullWidth: true,
      required: field.required,
      label: field.label,
      disabled: disabled,
      value: value,
      onChange: (e) => handleChange(field.field_id, e.target.value, field.field_type),
      sx: { mb: 2 },
    };

    const showDescriptionTooltip = field.description && field.keyword !== "gdpr" && field.keyword !== "marketing";

    const tooltipIcon = showDescriptionTooltip ? (
      <Tooltip title={field.description} arrow placement="top-start">
        <IconButton size="small">
          <HelpIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    ) : null;

    // Render by field type
    let fieldComponent;
    switch (field.field_type) {
      case "text":
      case "textarea":
        fieldComponent = (
          <TextField
            {...commonProps}
            multiline={field.field_type === "textarea"}
            rows={field.field_type === "textarea" ? 4 : 1}
          />
        );
        break;
      case "email":
        fieldComponent = (
          <TextField
            {...commonProps}
            type="email"
            error={value && !isValidEmail(value)}
          />
        );
        break;
      case "number":
        const isPhone = field.keyword && field.keyword.toLowerCase().includes("phone");
        fieldComponent = (
          <TextField
            {...commonProps}
            type={isPhone ? "tel" : "number"}
            inputProps={isPhone ? { pattern: "[0-9]*", inputMode: "numeric" } : {}}
          />
        );
        break;
      case "select":
        {
          const options = field.options ? field.options.split(",") : [];
          fieldComponent = (
            <FormControl fullWidth required={field.required} disabled={disabled} sx={{ mb: 2 }}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                label={field.label}
                value={value}
                onChange={(e) => handleChange(field.field_id, e.target.value, field.field_type)}
              >
                {options.map((opt) => {
                  const trimmed = opt.trim();
                  return (
                    <MenuItem key={trimmed} value={trimmed}>
                      {trimmed}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        }
        break;
      case "radio":
        {
          const options = field.options ? field.options.split(",") : [];
          fieldComponent = (
            <FormControl component="fieldset" required={field.required} disabled={disabled} sx={{ mb: 2 }}>
              <Typography sx={{ mb: 1 }}>{field.label}</Typography>
              <RadioGroup
                value={value}
                onChange={(e) => handleChange(field.field_id, e.target.value, field.field_type)}
              >
                {options.map((opt) => {
                  const trimmed = opt.trim();
                  return (
                    <FormControlLabel
                      key={trimmed}
                      value={trimmed}
                      control={<Radio />}
                      label={trimmed}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          );
        }
        break;
      case "checkbox":
        {
          const checkedValues = value;
          if (field.keyword === "gdpr" || field.keyword === "marketing") {
            fieldComponent = (
              <Box mt={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedValues.includes(field.keyword)}
                        onChange={() => handleChange(field.field_id, field.keyword, "checkbox")}
                        disabled={disabled}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" gap={1}>
                        {checkedValues.includes(field.keyword) ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="disabled" />
                        )}
                        <Typography>{field.label.toUpperCase()}</Typography>
                      </Box>
                    }
                  />
                  <Typography variant="body2" mt={1}>
                    {renderDescription(field.description, field.keyword)}
                  </Typography>
                </FormGroup>
              </Box>
            );
          } else {
            const options = field.options ? field.options.split(",") : [];
            fieldComponent = (
              <FormGroup sx={{ mb: 2 }}>
                <Typography sx={{ mb: 1 }}>{field.label}</Typography>
                {options.map((opt) => {
                  const trimmed = opt.trim();
                  return (
                    <FormControlLabel
                      key={trimmed}
                      control={
                        <Checkbox
                          checked={checkedValues.includes(trimmed)}
                          onChange={() => handleChange(field.field_id, trimmed, "checkbox")}
                          disabled={disabled}
                        />
                      }
                      label={trimmed}
                    />
                  );
                })}
              </FormGroup>
            );
          }
        }
        break;
      default:
        fieldComponent = null;
    }

    return (
      <Box key={field.field_id} display="flex" alignItems="flex-start" gap={1}>
        <Box flexGrow={1}>{fieldComponent}</Box>
        {tooltipIcon}
      </Box>
    );
  };

  const handleCreateSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);
    try {
      const fieldResponses = formData.pages.flatMap((page) =>
        page.groups.flatMap((group) =>
          group.fields.map((field) => ({
            field: field.id,
            value: formState[field.field_id] || "",
          }))
        )
      );

      const result = await submitFormResponse(formData.id, fieldResponses);
      trackChatEvent.formInteraction("personal_data", "form_submitted", {
        display_count: formDisplayCount,
      });

      saveFieldsToLocalStorage();
      setIsSubmitted(true);
      setIsEditing(false);
      setOriginalFormState({ ...formState, id: result.id });
      localStorage.setItem("isSubmitted", JSON.stringify(true));
      localStorage.setItem("formState", JSON.stringify({ ...formState, id: result.id }));
      
      await onSendMessage("Dati personali salvati", null, null, formState);

      setSnackbar({
        open: true,
        message: "Modulo inviato con successo!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("Impossibile inviare il modulo. Riprova.");
      trackChatEvent.formInteraction("personal_data", "form_submission_failed", {
        display_count: formDisplayCount,
        error: error.message,
      });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleUpdateSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);
    try {
      const fieldResponses = formData.pages.flatMap((page) =>
        page.groups.flatMap((group) =>
          group.fields.map((field) => ({
            field: field.id,
            value: formState[field.field_id] || "",
          }))
        )
      );

      await submitFormResponse(formData.id, fieldResponses);
      saveFieldsToLocalStorage();

      setIsEditing(false);
      setOriginalFormState({ ...formState });
      localStorage.setItem("formState", JSON.stringify(formState));
      
      await onSendMessage("Dati personali aggiornati", null, null, formState);

      setSnackbar({
        open: true,
        message: "Dati salvati con successo!",
        severity: "success",
      });
      trackChatEvent.saveForm("personal_data");
    } catch (error) {
      console.error("Form update failed:", error);
      setError("Impossibile aggiornare il modulo. Riprova più tardi.");
      setSnackbar({
        open: true,
        message: "Impossibile aggiornare il modulo. Riprova più tardi.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const saveFieldsToLocalStorage = () => {
    const fieldsToSave = [
      { keyword: "is_company" },
      { keyword: "first_name" },
      { keyword: "last_name" },
      { keyword: "name" },
      { keyword: "email" },
      { keyword: "phone" },
      { keyword: "gdpr" },
      { keyword: "marketing" },
    ];

    fieldsToSave.forEach((field) => {
      const fieldId = findFieldIdByKeyword(field.keyword);
      if (fieldId) {
        const value = formState[fieldId];
        if (value !== undefined && value !== null) {
          const storageKey = `aitomotivelab_personalData_${field.keyword}`;
          let storageValue = Array.isArray(value)
            ? value.join(",")
            : typeof value === "object"
            ? JSON.stringify(value)
            : String(value);
          localStorage.setItem(storageKey, storageValue);
        }
      }
    });
  };

  const findFieldIdByKeyword = (keyword) => {
    for (const page of formData?.pages || []) {
      for (const group of page.groups) {
        const field = group.fields.find((f) => f.keyword === keyword);
        if (field) {
          return field.field_id;
        }
      }
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isSubmitted) {
        handleUpdateSubmit();
      } else {
        handleCreateSubmit();
      }
    } else {
      setSnackbar({
        open: true,
        message: "Compila tutti i campi obbligatori.",
        severity: "warning",
      });
    }
  };

  const handleEditToggle = () => {
    if (isEditing) {
      // If currently editing and there are unsaved changes, confirm
      if (hasChanges && !window.confirm("Hai modifiche non salvate. Vuoi scartarle?")) {
        return;
      }
      setFormState(originalFormState);
      setHasChanges(false);
      setIsEditing(false);
    } else {
      // Start editing
      setOriginalFormState({ ...formState });
      setHasChanges(false);
      setIsEditing(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };

  const renderCurrentField = () => {
    const currentField = visibleFields[currentFieldIndex];
    if (!currentField) return null;

    return (
      <Box
        sx={{
          animation: fieldTransition ? 'slideIn 0.3s ease-out' : 'none',
          '@keyframes slideIn': {
            from: { opacity: 0, transform: 'translateX(20px)' },
            to: { opacity: 1, transform: 'translateX(0)' }
          }
        }}
      >
        {renderField(currentField)}
        
        <Box mt={3} display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            disabled={currentFieldIndex === 0}
            onClick={() => {
              setFieldTransition(true);
              setTimeout(() => setCurrentFieldIndex(prev => prev - 1), 50);
              setTimeout(() => setFieldTransition(false), 300);
            }}
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
              minWidth: 120,
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              }
            }}
          >
            Precedente
          </Button>
          <Button
            variant="contained"
            endIcon={currentFieldIndex === visibleFields.length - 1 ? 
              <SaveIcon /> : <ArrowForwardIcon />}
            onClick={() => {
              setFieldTransition(true);
              setTimeout(() => {
                if (currentFieldIndex === visibleFields.length - 1) {
                  handleSubmit({ preventDefault: () => {} });
                } else {
                  setCurrentFieldIndex(prev => prev + 1);
                }
              }, 50);
              setTimeout(() => setFieldTransition(false), 300);
            }}
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
              minWidth: 120,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              }
            }}
          >
            {currentFieldIndex === visibleFields.length - 1 ? 'Salva' : 'Avanti'}
          </Button>
        </Box>
      </Box>
    );
  };

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!formData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        p: 3,
        width: "100%", 
        boxSizing: "border-box",
        borderRadius: 4,
        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
        background: theme.palette.mode === 'dark' 
          ? 'linear-gradient(145deg, #1a1a1a 0%, #2d2d2d 100%)'
          : 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
      }}
    >
      {isSubmitted && (
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <IconButton onClick={handleEditToggle}>
            {isEditing ? <CloseIcon /> : <EditIcon />}
          </IconButton>
        </Box>
      )}

      <form onSubmit={e => e.preventDefault()}>
        <Box sx={{ mb: 3 }}>
          <Box 
            sx={{ 
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px',
              padding: '12px 16px',
              border: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Box 
              sx={{ 
                backgroundColor: theme.palette.primary.main,
                borderRadius: '50%',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
              }}
            >
              <CheckCircleIcon />
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                Unisciti a migliaia di clienti che hanno già scelto il nostro assistente personale 24/7! 🚀
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Il tuo consulente è sempre disponibile per trovare la soluzione perfetta per te. Personalizza la tua esperienza in pochi click! ✨
              </Typography>
            </Box>
          </Box>
        </Box>
        {renderCurrentField()}
      </form>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PersonalDataForm;
