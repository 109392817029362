export const fetchVehicleData = async (vehicleId) => {
  let attempts = 0;
  const maxAttempts = 20;
  const delayBetweenAttempts = 2000; // 1 second delay between retries
  let result = null;

  while (attempts < maxAttempts && !result) {
    try {
      const response = await fetch(
        `https://apicore.smiledealer.it/vehicles/stocks/${localStorage.getItem("codice_concessionario")}/${vehicleId}/large/`
      );
      const data = await response.json();
      result = data;
    } catch (error) {
      attempts++;
      console.error(`Error fetching vehicle data (attempt ${attempts}/${maxAttempts}):`, error);
      
      if (attempts < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
      }
    }
  }
  
  return result;
};

export const fetchVehicleDataSmall = async (vehicleId) => {
  let attempts = 0;
  const maxAttempts = 10;
  const delayBetweenAttempts = 1000; // 1 second delay between retries
  let result = null;

  while (attempts < maxAttempts && !result) {
    try {
      const response = await fetch(
        `https://apicore.smiledealer.it/vehicles/stocks/140/${vehicleId}/`
      );
      const data = await response.json();
      result = data;
    } catch (error) {
      attempts++;
      console.error(`Error fetching vehicle data (attempt ${attempts}/${maxAttempts}):`, error);
      
      if (attempts < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
      }
    }
  }
  
  return result;
};