import { ThemeProvider as ThemeMuiProvider, GlobalStyles } from "@mui/material";
import ChatWrapper from "./components/ChatWrapper";
import { ThemeProvider, useThemeContext } from "./context/Theme";

const ThemeCheck: React.FC = () => {
  const { loaded, theme } = useThemeContext();

  return loaded ? (
    <ThemeMuiProvider theme={theme}>
      <GlobalStyles styles={{ ".whatsapp-btn": { display: "none !important" } }} />
      <ChatWrapper />
    </ThemeMuiProvider>
  ) : null;
};

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <ThemeCheck />
    </ThemeProvider>
  );
};

export default App;
