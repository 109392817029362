import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  FaCalendarAlt,
  FaEuroSign,
  FaHeart,
  FaTachometerAlt,
  FaTags,
} from "react-icons/fa";
import { fetchVehicleData } from "../../../api/fetchVehicleData";
import "./CustomCarousel.css";
import NavigationDots from "./NavigationDots";
import VehicleDialog from "./VehicleDialog";
import { trackChatEvent } from '../../../utils/analytics';

const card_height = 430;
const card_width = 350;
const card_gap = 20;
const min_container_padding = window.innerWidth < 600 ? 16 : 48;

const getThemeValue = (theme, path, fallback) => {
  return (
    path.split(".").reduce((acc, part) => acc && acc[part], theme) || fallback
  );
};

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const MotionCard = motion(Card);
const MotionIconButton = motion(IconButton);
const MotionButton = motion(Button);

const CustomCarousel = ({ items, whishlist, onWishlistUpdate, onSendMessage  }) => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [open, setOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cardsPerView, setCardsPerView] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const theme = useTheme();
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  useEffect(() => {
    const updateCardsPerView = () => {
      if (containerRef.current) {
        const parentWidth = containerRef.current.parentElement.offsetWidth;
        const availableWidth = parentWidth - (2 * min_container_padding);
        
        if (availableWidth < card_width) {
          setContainerWidth(card_width);
          setCardsPerView(1);
        } else {
          const maxCards = Math.floor((availableWidth + card_gap) / (card_width + card_gap));
          const actualCards = Math.min(maxCards, items.length);
          setCardsPerView(actualCards);
          setContainerWidth((card_width + card_gap) * actualCards - card_gap);
        }
      }
    };

    const resizeObserver = new ResizeObserver(updateCardsPerView);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current.parentElement);
    }
    
    updateCardsPerView();
    
    return () => {
      resizeObserver.disconnect();
    };
  }, [items.length]);

  if (items.length === 0) return null;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      handleNext();
    } else if (isRightSwipe) {
      handlePrev();
    }
  };

  const handlePrev = () => {
    trackChatEvent.carouselNav('prev');
    setDirection(-1);
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - cardsPerView;
      return newIndex < 0 ? Math.max(0, items.length - cardsPerView) : newIndex;
    });
  };

  const handleNext = () => {
    trackChatEvent.carouselNav('next');
    setDirection(1);
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + cardsPerView;
      return newIndex >= items.length ? 0 : newIndex;
    });
  };

  const handleDotClick = (index) => {
    trackChatEvent.carouselNav(`dot-${index}`);
    setDirection(index > currentIndex ? 1 : -1);
    setCurrentIndex(index);
  };

  const handleOpenVehiclePopup = async (index) => {
    const vehicle = items[index];
    trackChatEvent.viewVehicleDetails(vehicle.vehicleid, vehicle.title);
    setOpen(true);
    setIsLoading(true);
    setVehicleData(null);
    
    try {
      const data = await fetchVehicleData(items[index].vehicleid);
      setVehicleData(data);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBookVisit = (item) => {
    trackChatEvent.bookVisit(item.vehicleid, item.title);
    onSendMessage?.(`Vorrei prenotare una visita per: ${item.title}`);
  };

  const handleOpenPage = (item) => {
    trackChatEvent.openVehiclePage(item.vehicleid, item.title);
    window.open(`${item.url}?cm=mia`, '_blank');
  };

  return (
    <>
      <Box
        ref={containerRef}
        className="carousel-container"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          overflow: "visible",
          marginBottom: "40px",
          width: "100%",
          minWidth: {
            xs: card_width,
            sm: card_width + (2 * min_container_padding)
          },
          touchAction: "pan-y pinch-zoom",
        }}
      >
        <div
          style={{
            width: containerWidth,
            height: card_height,
            position: "relative",
            margin: "0 auto",
          }}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentIndex}
              custom={direction}
              initial="incoming"
              animate="active"
              exit="exit"
              transition={{
                duration: window.innerWidth < 600 ? 0.5 : 0.8,
                ease: [0.4, 0, 0.2, 1],
              }}
              className="carousel"
              variants={{
                incoming: (direction) => ({
                  x: direction > 0 ? containerWidth : -containerWidth,
                  opacity: 0,
                  scale: 0.95,
                }),
                active: { 
                  x: 0, 
                  scale: 1, 
                  opacity: 1,
                  transition: {
                    duration: 0.6,
                    ease: [0.4, 0, 0.2, 1],
                  }
                },
                exit: (direction) => ({
                  x: direction > 0 ? -containerWidth : containerWidth,
                  opacity: 0,
                  scale: 0.95,
                }),
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                display: "flex",
                gap: `${card_gap}px`,
                width: containerWidth,
              }}
            >
              {items.slice(currentIndex, currentIndex + cardsPerView).map((item, index) => {
                const isWishlisted = whishlist?.some(
                  ({ vehicleid }) => vehicleid === item.vehicleid
                );

                return (
                  <MotionCard
                    key={`${item.vehicleid}-${index}`}
                    className="custom-card"
                    whileHover={{ 
                      y: -5,
                      transition: { duration: 0.2 }
                    }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.3,
                      delay: index * 0.1,
                    }}
                    sx={{
                      width: card_width,
                      height: card_height,
                      flexShrink: 0,
                      margin: 0,
                      boxShadow: 2,
                      borderRadius: "15px",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      backgroundColor: "#ffffff",
                      color: "#666666",
                      borderBottom: `0px solid ${getThemeValue(
                        theme,
                        "palette.divider",
                        "#e0e0e0"
                      )}`,
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        boxShadow: 6,
                      }
                    }}
                  >
                    <Box sx={{ position: "relative", overflow: "hidden" }}>
                    <CardMedia
                      component="img"
                      height="180"
                      src={item.image}
                      sx={{ 
                        objectFit: "cover", 
                        height: 180,
                        backgroundColor: 'transparent',
                        transition: "transform 0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.05)",
                        },
                        '&.MuiCardMedia-img': {
                          objectFit: 'cover',
                        }
                      }}
                      onError={(e) => {
                        const fallbackLogo = localStorage.getItem("logo") || `${process.env.REACT_APP_DOMAIN}/media/company_logos/3_ms-icon-144x144.png`;
                        e.target.src = fallbackLogo;
                        e.target.style.objectFit = 'contain';
                        e.target.style.padding = '20px';
                        e.target.style.backgroundColor = '#f5f5f5';
                      }}
                    />
                      {item.available && (
                        <MotionIconButton
                          onClick={() => {
                            const vehicleId = item.vehicleid;
                            trackChatEvent.toggleWishlist(vehicleId, !isWishlisted);
                            onWishlistUpdate(vehicleId);
                          }}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                            },
                            transition: "all 0.3s ease",
                          }}
                        >
                          <motion.div
                            animate={isWishlisted ? { scale: [1, 1.2, 1] } : {}}
                            transition={{ duration: 0.3 }}
                          >
                            <FaHeart
                              color={isWishlisted ? "red" : "rgba(0, 0, 0, 0.1)"}
                              style={{ transition: "all 0.3s ease" }}
                            />
                          </motion.div>
                        </MotionIconButton>
                      )}
                    </Box>

                    {/* Rest of the card content remains the same, just wrap buttons in MotionButton */}
                    {/* ... [Keep all the existing content] ... */}
                    {item.badge && (
                      <Chip
                        label={item.badge}
                        className="card-chip"
                        sx={{
                          position: "absolute",
                          top: 8,
                          left: 8,
                          zIndex: 1,
                          backgroundColor: theme.palette.v2.primary,
                          color: theme.palette.v2.textPrimary,
                          fontSize: "0.7rem",
                          height: "24px",
                        }}
                      />
                    )}
                    {!item.available && (
                      <Chip
                        label="Non più disponibile"
                        className="card-chip"
                        sx={{
                          position: "absolute",
                          top: item.badge ? 40 : 8,
                          left: 8,
                          zIndex: 1,
                          backgroundColor: theme.palette.error.main,
                          color: "white",
                          fontSize: "0.7rem",
                          height: "24px",
                        }}
                      />
                    )}
                    <CardContent sx={{ flexGrow: 1, padding: "12px" }}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{
                          fontFamily: getThemeValue(
                            theme,
                            "typography.fontFamily",
                            "'Roboto', 'Helvetica', 'Arial', sans-serif"
                          ),
                          fontWeight: "bold",
                          fontSize: "1.1rem",
                          marginBottom: "8px",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Box sx={{ display: "flex", marginBottom: "4px" }}>
                        {item.description1 && (
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: getThemeValue(
                                theme,
                                "typography.fontFamily",
                                "'Roboto', 'Helvetica', 'Arial', sans-serif"
                              ),
                              fontSize: "0.85rem",
                              fontWeight: 500,
                              display: "flex",
                              alignItems: "center",
                              marginRight: "auto",
                            }}
                          >
                            <FaTachometerAlt
                              style={{
                                marginRight: "4px",
                                fontSize: "0.85rem",
                              }}
                            />{" "}
                            {formatPrice(item.description1)}
                          </Typography>
                        )}
                        {item.description3 && (
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: getThemeValue(
                                theme,
                                "typography.fontFamily",
                                "'Roboto', 'Helvetica', 'Arial', sans-serif"
                              ),
                              fontSize: "0.85rem",
                              fontWeight: 500,
                              display: "flex",
                              alignItems: "center",
                              marginLeft: item.description1 ? "auto" : "0",
                            }}
                          >
                            <FaCalendarAlt
                              style={{
                                marginRight: "4px",
                                fontSize: "0.85rem",
                              }}
                            />{" "}
                            {item.description3}
                          </Typography>
                        )}
                      </Box>
                      {item.description2 && (
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.2 }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontFamily: getThemeValue(
                                theme,
                                "typography.fontFamily",
                                "'Roboto', 'Helvetica', 'Arial', sans-serif"
                              ),
                              fontSize: "2rem",
                              marginBottom: "6px",
                              fontWeight: 900,
                            }}
                          >
                            <FaEuroSign
                              style={{
                                marginRight: "-10px",
                                fontSize: "1.5rem",
                              }}
                            />{" "}
                            {formatPrice(item.description2)}
                          </Typography>
                        </motion.div>
                      )}
                      {item.description4 > 0 &&
                        item.description4 > 0 &&
                        parseFloat(item.description4) >
                          parseFloat(item.description2) && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "8px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                textDecoration: "line-through",
                                marginRight: "8px",
                              }}
                            >
                              <FaEuroSign
                                style={{
                                  marginRight: "2px",
                                  fontSize: "0.8rem",
                                }}
                              />
                              {formatPrice(item.description4)}
                            </Typography>
                            <Chip
                              label={`-${Math.round(
                                (1 -
                                  parseFloat(item.description2) /
                                    parseFloat(item.description4)) *
                                  100
                              )}%`}
                              color="error"
                              size="small"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                              }}
                            />
                          </Box>
                        )}
                      {item.promotion && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.95 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ delay: 0.3 }}
                        >
                          <Box
                            sx={{
                              mt: 1,
                              p: 1,
                              background: `linear-gradient(135deg, rgb(224, 195, 252) 30%, rgb(142, 197, 252) 90%)`,
                              borderRadius: "8px",
                              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                              transform: "translateZ(0)",
                              transition: "transform 0.2s ease-in-out",
                              "&:hover": {
                                transform: "translateZ(0) scale(1.02)",
                              }
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              component="div"
                              sx={{
                                fontFamily: getThemeValue(
                                  theme,
                                  "typography.fontFamily",
                                  "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                ),
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "0.9rem",
                              }}
                            >
                              <FaTags
                                style={{
                                  marginRight: "4px",
                                  fontSize: "0.8rem",
                                  color: "#6A0572",
                                }}
                              />{" "}
                              Scopri la promozione!
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: getThemeValue(
                                  theme,
                                  "typography.fontFamily",
                                  "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                ),
                                fontWeight: "400",
                                marginTop: "4px",
                                fontSize: "0.8rem",
                              }}
                            >
                              {item.descrizione_economica_promozione_ai}
                            </Typography>
                            <MotionButton
                              size="small"
                              href={
                                item.link_promozione_promozione_ai !== "NULL"
                                  ? item.link_promozione_promozione_ai
                                  : "#"
                              }
                              target="_blank"
                              variant="contained"
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              sx={{
                                mt: 1,
                                fontFamily: getThemeValue(
                                  theme,
                                  "typography.fontFamily",
                                  "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                ),
                                borderRadius: "20px",
                                boxShadow: 1,
                                background: getThemeValue(
                                  theme,
                                  "palette.primary.main",
                                  "#0073e6"
                                ),
                                fontSize: "0.7rem",
                                padding: "4px 8px",
                              }}
                            >
                              Scopri di più
                            </MotionButton>
                          </Box>
                        </motion.div>
                      )}
                    </CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        p: 1,
                        gap: 1.5,
                      }}
                    >
                      {item.available ? (
                        <>
                          <MotionButton
                            size="large"
                            onClick={() => handleBookVisit(item)}
                            variant="contained"
                            whileHover={{ scale: 1.03, y: -2 }}
                            whileTap={{ scale: 0.98 }}
                            startIcon={<FaCalendarAlt style={{ fontSize: '1.2rem' }} />}
                            sx={{
                              fontFamily: getThemeValue(
                                theme,
                                "typography.fontFamily",
                                "'Roboto', 'Helvetica', 'Arial', sans-serif"
                              ),
                              borderRadius: "20px",
                              boxShadow: "0 4px 15px rgba(0,0,0,0.2)",
                              background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                              color: "white",
                              fontSize: "0.9rem",
                              padding: "10px 24px",
                              width: "100%",
                              position: "relative",
                              overflow: "hidden",
                              transition: "all 0.3s ease-in-out",
                              textTransform: "none",
                              fontWeight: 600,
                              "&::before": {
                                content: '""',
                                position: "absolute",
                                top: 0,
                                left: "-100%",
                                width: "100%",
                                height: "100%",
                                background: "linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)",
                                transition: "all 0.6s ease",
                              },
                              "&:hover": {
                                boxShadow: "0 6px 20px rgba(0,0,0,0.3)",
                                "&::before": {
                                  left: "100%",
                                },
                              },
                            }}
                          >
                            Prenota Visita
                          </MotionButton>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1.5,
                              width: '100%',
                            }}
                          >
                            <MotionButton
                              size="small"
                              onClick={() => handleOpenPage(item)}
                              variant="contained"
                              whileHover={{ scale: 1.05, y: -2 }}
                              whileTap={{ scale: 0.95 }}
                              sx={{
                                fontFamily: getThemeValue(
                                  theme,
                                  "typography.fontFamily",
                                  "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                ),
                                borderRadius: "20px",
                                boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                                backgroundColor: theme.palette.v2.primary,
                                color: theme.palette.v2.textPrimary,
                                fontSize: "0.75rem",
                                padding: "6px 16px",
                                flex: 1,
                                position: "relative",
                                overflow: "hidden",
                                transition: "all 0.3s ease-in-out",
                                "&::after": {
                                  content: '""',
                                  position: "absolute",
                                  top: 0,
                                  left: "-100%",
                                  width: "100%",
                                  height: "100%",
                                  background: "linear-gradient(120deg, transparent, rgba(255,255,255,0.2), transparent)",
                                  transition: "all 0.6s ease",
                                },
                                "&:hover": {
                                  boxShadow: "0 6px 20px rgba(0,0,0,0.15)",
                                  backgroundColor: theme.palette.v2.primary,
                                  "&::after": {
                                    left: "100%",
                                  },
                                },
                              }}
                            >
                              Apri Pagina
                            </MotionButton>
                            <MotionButton
                              size="small"
                              onClick={() => handleOpenVehiclePopup(currentIndex + index)}
                              variant="outlined"
                              whileHover={{ scale: 1.05, y: -2 }}
                              whileTap={{ scale: 0.95 }}
                              sx={{
                                fontFamily: getThemeValue(
                                  theme,
                                  "typography.fontFamily",
                                  "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                ),
                                borderRadius: "20px",
                                color: theme.palette.v2.primary,
                                borderColor: theme.palette.v2.primary,
                                borderWidth: 2,
                                borderStyle: "solid",
                                fontSize: "0.75rem",
                                padding: "6px 16px",
                                flex: 1,
                                position: "relative",
                                overflow: "hidden",
                                transition: "all 0.3s ease-in-out",
                                backgroundColor: "transparent",
                                "&::after": {
                                  content: '""',
                                  position: "absolute",
                                  top: 0,
                                  left: "-100%",
                                  width: "100%",
                                  height: "100%",
                                  background: `linear-gradient(120deg, transparent, ${theme.palette.v2.primary}20, transparent)`,
                                  transition: "all 0.6s ease",
                                },
                                "&:hover": {
                                  boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                                  borderColor: theme.palette.v2.primary,
                                  "&::after": {
                                    left: "100%",
                                  },
                                },
                              }}
                            >
                              Dettagli
                            </MotionButton>
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  </MotionCard>
                );
              })}
            </motion.div>
          </AnimatePresence>

          {items.length > cardsPerView && (
            <>
              <MotionIconButton
                className="carousel-arrow prev-arrow"
                onClick={handlePrev}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                aria-label="Previous"
                sx={{
                  position: "absolute",
                  left: { xs: 8, sm: -48 },
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.5)!important",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)!important",
                  },
                  borderRadius: "50%",
                  width: { xs: "32px", sm: "40px" },
                  height: { xs: "32px", sm: "40px" },
                  cursor: "pointer",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <motion.svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="-4 0 24 24"
                  width="24"
                  height="24"
                  whileHover={{ scale: 1.1 }}
                >
                  <path
                    d="M11.67 3.87 9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"
                    style={{ fill: "white" }}
                  />
                </motion.svg>
              </MotionIconButton>
              <MotionIconButton
                className="carousel-arrow next-arrow"
                onClick={handleNext}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                aria-label="Next"
                sx={{
                  position: "absolute",
                  right: { xs: 8, sm: -48 },
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.5)!important",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)!important",
                  },
                  borderRadius: "50%",
                  width: { xs: "32px", sm: "40px" },
                  height: { xs: "32px", sm: "40px" },
                  cursor: "pointer",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <motion.div whileHover={{ scale: 1.1 }}>
                  <ArrowForwardIos
                    sx={{
                      color: "white",
                      fontSize: "24px",
                    }}
                  />
                </motion.div>
              </MotionIconButton>
            </>
          )}
        </div>
        <div style={{ height: 50 }}>
          <NavigationDots
            items={items}
            currentIndex={currentIndex}
            handleDotClick={handleDotClick}
            cardsPerView={cardsPerView}
          />
        </div>
      </Box>
      <VehicleDialog
        open={open}
        handleClose={handleClose}
        vehicleData={vehicleData}
        vehicleid={items[currentIndex].vehicleid}
        isLoading={isLoading}
      />
    </>
  );
};

export default CustomCarousel;