import { FaEuroSign, FaTachometerAlt, FaCalendarAlt } from 'react-icons/fa';




export const getPersonalDataFromLocalStorage = () => ({
  first_name: localStorage.getItem('aitomotivelab_personalData_first_name') || '',
  last_name: localStorage.getItem('aitomotivelab_personalData_last_name') || '',
  email: localStorage.getItem('aitomotivelab_personalData_email') || '',
  gdpr_consent: localStorage.getItem('aitomotivelab_personalData_gdpr') === 'gdpr',
  marketing_consent: localStorage.getItem('aitomotivelab_personalData_marketing') === 'marketing',
  phone: localStorage.getItem('aitomotivelab_personalData_phone') || '',
  api_token: localStorage.getItem('api_token') || ''
});


export const propertyMappings = {
  car_stock_search: {
    badge: 'usagetype',
    vehicleid: 'vehicleid',
    title: 'description',
    url: 'url',
    available:'available',
    image: 'urlmainimage',
    description1: 'km',
    description1Icon: FaTachometerAlt,
    description1String: 'km',
    description2: 'saleprice',
    description2Icon: FaEuroSign,
    description2String: '',
    description3: 'registrationyear',
    description3Icon: FaCalendarAlt,
    description3String: '',
    description4: 'retailprice',
    description4Icon: FaEuroSign,
    description4String: '',
    description5: 'sconto_attivo',
    description5Icon: FaEuroSign,
    description5String: '',
    descrizione_economica_promozione_ai: "descrizione_economica_promozione_ai",
    promotion: "promotion",
    link_promozione_promozione_ai: "link_promozione_promozione_ai"
  }
};


const fetchVehicleDataa = async (vehicleId) => {
  try {
    const response = await fetch(`https://apicore.smiledealer.it/vehicles/stocks/${localStorage.getItem("codice_concessionario")}/${vehicleId}/large/`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching vehicle data:', error);
    return null;
  }
};


export const mapItems = (source, mappings) => 
  source
    .filter(car => car[mappings.vehicleid])  // Filter out cars without a vehicleid
    .map(car => {
      const mappedCar = {
        vehicleid: car[mappings.vehicleid],
        badge: car[mappings.badge],
        title: car[mappings.title],
        url: car[mappings.url],
        image: car[mappings.image],
        available: car[mappings.available],
        description1Icon: mappings.description1Icon,
        description1String: mappings.description1String,
        description2Icon: mappings.description2Icon,
        description2String: mappings.description2String,
        description3: car[mappings.description3],
        description3Icon: mappings.description3Icon,
        description3String: mappings.description3String,

        description4: car[mappings.description4],
        description4Icon: mappings.description4Icon,
        description4String: mappings.description4String,

        description5: car[mappings.description5],
        description5Icon: mappings.description5Icon,
        description5String: mappings.description5String,

        
        descrizione_economica_promozione_ai: car[mappings.descrizione_economica_promozione_ai],
        promotion: car[mappings.promotion],
        link_promozione_promozione_ai: car[mappings.link_promozione_promozione_ai]
      };

      // Conditionally include description1 and description2
      if (car[mappings.description1] > 0) {
        mappedCar.description1 = car[mappings.description1];
      }
      if (car[mappings.description2] > 0) {
        mappedCar.description2 = car[mappings.description2];
      }
      if (car[mappings.description3] > 1990) {
        mappedCar.description3 = car[mappings.description3];
      }

      return mappedCar;
    });