import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";


const ScrollableContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  
  /* Hide default scrollbar buttons */
  &::-webkit-scrollbar-button {
    display: none;
  }

  /* Webkit browsers */
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.primaryColor};
    border-radius: 3px;
    
    &:hover {
      background: ${props => props.primaryColor}dd;
    }
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${props => props.primaryColor} transparent;

  /* Hide scrollbar when not in use */
  &::-webkit-scrollbar-thumb {
    transition: background-color 0.2s;
  }

  /* Ensure content doesn't overlap scrollbar */
  padding-right: 6px;
`;

const CustomScrollbar = ({ children, className, ...props }) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <StyledComponentsThemWrapper>
      <ScrollableContainer 
        className={className} 
        primaryColor={primaryColor}
        {...props}
      >
        {children}
      </ScrollableContainer>
    </StyledComponentsThemWrapper>
  );
};

export default CustomScrollbar;