import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { mapItems, propertyMappings } from "../utils/utils";
import { postWhislistApi } from "./api";
import { trackChatEvent } from "../utils/analytics";

const API_DOMAIN = process.env.API_DOMAIN || "https://admin.aitomotivelab.com";
const WS_DOMAIN = process.env.WEBSOCKET_URL || "wss://admin.aitomotivelab.com";

// WebSocket paths
const WS_PATH = "/ws/ai_response/";
const WEBSOCKET_URL = `${WS_DOMAIN}${WS_PATH}`;

// API paths
const TOKEN_PATH = "/user_management/oauth/token/";
const SET_COOKIES_PATH = "/ai_app/set_cookies/";

const TOKEN_URL = `${API_DOMAIN}${TOKEN_PATH}`;
const SET_COOKIES_URL = `${API_DOMAIN}${SET_COOKIES_PATH}`;

const api = axios.create();

let areCookiesSet = false;
let client_id = null;
let client_secret = null;
let grant_type = null;
let api_token = null;
let form_registration_id = null;
let accessToken = null;
let codice_concessionario = null;
let primary_color = null;
let secondary_color = null;
let background_color = null;
let text_color = null;
let logo = null;
let name_chat = null;

let lastEventTimestamp = 0;

const FORM_TRACKING_DEBOUNCE = 500; // 500ms debounce

const loggedCarousels = new Set(); // Track logged carousels

const useChatWebSocket = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedWishlist, setLoadedWishlist] = useState(false);
  const [colors, setColors] = useState(null);

  const [chatId, setChatId] = useState(() => {
    const storedChatId = localStorage.getItem(
      "aitomotivelab_personalData_chat_id"
    );
    return storedChatId &&
      storedChatId !== "undefined" &&
      storedChatId !== "null"
      ? storedChatId
      : null;
  });
  const chatIdRef = useRef(chatId);

  useEffect(() => {
    chatIdRef.current = chatId;
    if (
      chatId &&
      (!localStorage.getItem("aitomotivelab_personalData_chat_id") ||
        localStorage.getItem("aitomotivelab_personalData_chat_id") ===
          "undefined" ||
        localStorage.getItem("aitomotivelab_personalData_chat_id") === "null")
    ) {
      localStorage.setItem("aitomotivelab_personalData_chat_id", chatId);
    }
  }, [chatId]);

  const [wishlistItems, setWishlistItems] = useState([]);
  const [error, setError] = useState(null);
  const lastUserMessage = useRef("");
  const currentMessageRef = useRef(null);
  const websocketRef = useRef(null);
  const initializeWebSocketRef = useRef(null);
  const [isHumanControlled, setIsHumanControlled] = useState(false);
  const [humanControlMessage, setHumanControlMessage] = useState("");

  const [isReconnecting, setIsReconnecting] = useState(false);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttempts = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 5;
  const RECONNECT_INTERVAL = 5000; // 5 seconds

  const [reconnectCountdown, setReconnectCountdown] = useState(0);

  const hasTrackedFormShown = useRef(false);
  const formDisplayCount = useRef(0);
  const lastTrackingTimestamp = useRef(0);
  const hasTrackedRef = useRef(false);

  // Initialize from localStorage and cleanup on unmount
  useEffect(() => {
    const storedCount = localStorage.getItem('form_display_count');
    if (storedCount) {
      formDisplayCount.current = parseInt(storedCount, 10);
    }
    
    return () => {
      hasTrackedFormShown.current = false;
    };
  }, []);

  const trackFormShown = useCallback(() => {
    const now = Date.now();
    // Prevent tracking if less than debounce time has passed
    if (now - lastTrackingTimestamp.current < FORM_TRACKING_DEBOUNCE) {
      return;
    }

    if (!hasTrackedFormShown.current) {
      hasTrackedFormShown.current = true;
      lastTrackingTimestamp.current = now;
      formDisplayCount.current += 1;
      localStorage.setItem('form_display_count', formDisplayCount.current.toString());
      
      trackChatEvent.formInteraction('personal_data', 'form_shown', {
        display_count: formDisplayCount.current,
        timestamp: new Date().toISOString()
      });
    }
  }, []);

  const initializeApi = async () => {
    if (areCookiesSet) return;

    try {
      const response = await api.post(SET_COOKIES_URL, {
        fullUrl: window.location.href // Pass full URL in the body
      });
      client_id = response.data.client_id;
      client_secret = response.data.client_secret;
      grant_type = response.data.grant_type;
      api_token = response.data.api_token;
      form_registration_id = response.data.form_registration_id;
      primary_color = response.data.primary_color;
      secondary_color = response.data.secondary_color;
      background_color = response.data.background_color;
      text_color = response.data.text_color;
      logo = response.data.logo;
      name_chat = response.data.name;
      codice_concessionario = response.data.codice_concessionario;

      //set colors using setColors state
      setColors({
        primary: response.data.primary_color,
        textPrimary: response.data.text_primary_color,
        secondary: response.data.secondary_color,
        textSecondary: response.data.text_secondary_color,
      });

      areCookiesSet = true;
      localStorage.setItem("api_token", api_token);
      localStorage.setItem("primary_color", primary_color);
      localStorage.setItem("secondary_color", secondary_color);
      localStorage.setItem("background_color", background_color);
      localStorage.setItem("text_color", text_color);
      localStorage.setItem("logo", logo);
      localStorage.setItem("name_chat", name_chat);
      localStorage.setItem("codice_concessionario", codice_concessionario);
      localStorage.setItem("company_name", response.data.company_name);
      localStorage.setItem("welcome_message", response.data.welcome_message);
      localStorage.setItem("message_1", response.data.message_1);
      localStorage.setItem("message_2", response.data.message_2);
      localStorage.setItem("message_3", response.data.message_3);
    } catch (error) {
      console.error("Error initializing API:", error);
      setError("Failed to initialize API. Please refresh the page.");
    }
  };

  const getAccessToken = async () => {
    if (accessToken) {
      return accessToken;
    }

    try {
      const response = await api.post(
        TOKEN_URL,
        {
          client_id: client_id,
          client_secret: client_secret,
          grant_type: grant_type,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      accessToken = response.data.access_token;
      return accessToken;
    } catch (error) {
      console.error("Error obtaining access token:", error);
      throw new Error("Failed to obtain access token");
    }
  };

  // Define attemptReconnect first
  const attemptReconnect = useCallback(() => {
    if (isReconnecting) return;

    setIsReconnecting(true);
    reconnectAttempts.current += 1;

    if (reconnectAttempts.current <= MAX_RECONNECT_ATTEMPTS) {
      const delay =
        RECONNECT_INTERVAL * Math.pow(2, reconnectAttempts.current - 1);
      setReconnectCountdown(Math.floor(delay / 1000));

      reconnectTimeoutRef.current = setTimeout(() => {
        if (initializeWebSocketRef.current) {
          initializeWebSocketRef.current();
        }
      }, delay);
    } else {
      console.log("Max reconnection attempts reached");
      setError(
        "Failed to reconnect after multiple attempts. Please try again."
      );
      setIsReconnecting(false);
    }
  }, [isReconnecting]);

  // Then define initializeWebSocket
  const initializeWebSocket = useCallback(async () => {
    if (websocketRef.current) {
      websocketRef.current.close();
    }

    try {
      await initializeApi();
      const token = await getAccessToken();

      const ws = new WebSocket(WEBSOCKET_URL);
      websocketRef.current = ws;

      ws.onopen = () => {
        setIsConnected(true);
        setIsReconnecting(false);
        reconnectAttempts.current = 0;
        setError(null);
        ws.send(
          JSON.stringify({
            type: "authenticate",
            token: token,
            fullUrl: window.location.href,
          })
        );
      };

      ws.onmessage = handleWebSocketMessage;

      ws.onclose = (event) => {
        setIsConnected(false);
        setIsAuthenticated(false);
        if (event.code !== 1000) {
          setError("WebSocket disconnected. Attempting to reconnect...");
          attemptReconnect();
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        setIsConnected(false);
        setError("WebSocket connection error. Attempting to reconnect...");
        attemptReconnect();
      };
    } catch (error) {
      console.error("Error initializing WebSocket:", error);
      setError(
        error.message || "Failed to initialize chat. Attempting to reconnect..."
      );
      attemptReconnect();
    }
  }, [attemptReconnect]);

  // Assign initializeWebSocket to the ref
  initializeWebSocketRef.current = initializeWebSocket;

  useEffect(() => {
    initializeWebSocket();
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [initializeWebSocket]);

  useEffect(() => {
    let countdownInterval;
    if (reconnectCountdown > 0) {
      countdownInterval = setInterval(() => {
        setReconnectCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
  }, [reconnectCountdown]);

  const handleManualReconnect = () => {
    reconnectAttempts.current = 0;
    setError(null);
    if (initializeWebSocketRef.current) {
      initializeWebSocketRef.current();
    }
  };

  const handleWebSocketMessage = (event) => {
    const data = JSON.parse(event.data);
    // console.log("Received data:", data);

    if (data.type === "authentication") {
      setIsAuthenticated(data.success);
      if (!data.success) {
        setError("Authentication failed. Please refresh the page.");
      } else {
        const storedChatId = localStorage.getItem(
          "aitomotivelab_personalData_chat_id"
        );
        if (
          storedChatId &&
          storedChatId !== "undefined" &&
          storedChatId !== "null"
        ) {
          if (event.target && event.target.readyState === WebSocket.OPEN) {
            event.target.send(
              JSON.stringify({
                type: "join_chat",
                chat_id: storedChatId,
              })
            );
          } else {
            setError("WebSocket is not connected. Please try again.");
          }
        } else {
          if (event.target && event.target.readyState === WebSocket.OPEN) {
            event.target.send(
              JSON.stringify({
                type: "create_chat",
              })
            );
          } else {
            setError("WebSocket is not connected. Please try again.");
          }
        }
      }
    } else if (data.type === "chat_created" || data.type === "chat_joined") {
      if (!chatIdRef.current) {
        setChatId(data.chat_id);
        localStorage.setItem(
          "aitomotivelab_personalData_chat_id",
          data.chat_id
        );
      }
      fetchChatHistory();
    } else if (data.type === "error" && data.message === "Chat not found") {
      setChatId(null);
      chatIdRef.current = null;
      if (event.target && event.target.readyState === WebSocket.OPEN) {
        event.target.send(
          JSON.stringify({
            type: "create_chat",
          })
        );
      } else {
        setError("WebSocket is not connected. Please try again.");
      }
    } else if (
      data.type === "stream_message" ||
      data.type === "message_complete" ||
      data.type === "admin_message" ||
      data.type === "predicted_questions"
    ) {
      handleIncomingMessage(data);
    } else if (data.type === "human_control_update") {
      setIsHumanControlled(data.human_control);
      setHumanControlMessage(data.message || "An operator is now available.");
    } else if (data.type === "chat_history") {
      let parsedHistory;
      try {
        parsedHistory = JSON.parse(data.chat_history);
      } catch (parseError) {
        setError("Invalid chat history format");
        return;
      }
      const formattedHistory = parsedHistory.flatMap((item) =>
        formatChatHistoryItem(item.fields, item.pk)
      );
      setMessages(formattedHistory);
    }
  };

  const fetchChatHistory = () => {
    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      websocketRef.current.send(
        JSON.stringify({
          type: "fetch_history",
        })
      );
    } else {
      setError("Failed to fetch chat history. Please try again.");
    }
  };

  const formatChatHistoryItem = (message, id) => {
    const formattedMessages = [];
    if (
      message.sender_message !== null 
    ) {
      formattedMessages.push({
        sender: "user",
        text: message.sender_message,
        id,
        file_url: message.sender_file_url || null,
      });
    } else if (message.sender_file_url) {
      formattedMessages.push({
        sender: "user",
        text: "File inviato",
        id,
        file_url: message.sender_file_url,
      });
    }

    // First push the bot message if it exists
    if (message.bot_message || (message.tool && message.tool.includes("car_stock"))) {
      let formattedMessage = {
        sender: "server",
        tool: message.tool || null,
        text: message.bot_message || "",
        id: `${id}-message`,
        file_url: message.bot_file_url || null,
        whishlist: message.whishlist,
      };

      if (
        message.sources &&
        message.tool &&
        message.tool.includes("car_stock")
      ) {
        try {
          if (
            Array.isArray(message.sources) &&
            propertyMappings[message.tool]
          ) {
            formattedMessage.carouselItems = mapItems(
              message.sources,
              propertyMappings[message.tool]
            );
          }
        } catch (error) {
          console.warn("Error processing carousel items:", error);
        }
      }

      formattedMessages.push(formattedMessage);
    }

    // Then push predicted questions as a separate message if they exist
    if (message.predicted_questions) {
      // Parse the JSON string at formatting time
      let parsedQuestions;
      try {
        parsedQuestions = JSON.parse(message.predicted_questions);
      } catch (e) {
        console.error('Failed to parse predicted questions:', e);
        parsedQuestions = null;
      }
    
      if (parsedQuestions) {
        formattedMessages.push({
          sender: "server",
          type: "predicted_questions",
          questions: parsedQuestions, // Store as parsed object using the same key as live messages
          id: `${id}-questions`,
        });
      }
    }

    return formattedMessages;
  };

  const getAllVehicleInfosFromMessages = () => {
    const allVehicleInfo = messages?.reduce((r, v) => {
      if (v.carouselItems) {
        return [...r, ...v.carouselItems];
      }
      return r;
    }, []);
    return allVehicleInfo;
  };

  const updateWishlistFromMessages = () => {
    const vehiclesInfo = getAllVehicleInfosFromMessages();
    const messageWithWishlist = messages?.find(({ whishlist }) =>
      Boolean(whishlist)
    );
    const wishlistWithInfo = messageWithWishlist?.whishlist
      .split(", ")
      ?.map((id) => vehiclesInfo.find(({ vehicleid }) => vehicleid === id))
      .filter(item => item && item.available !== false); // Filter out unavailable vehicles
    setWishlistItems(wishlistWithInfo || []);
  };

  useEffect(() => {
    if (messages.length > 0 && !loadedWishlist) {
      updateWishlistFromMessages();
      setLoadedWishlist(true);
    }
  }, [messages, loadedWishlist, updateWishlistFromMessages, setLoadedWishlist]);

  const handleWishlistUpdate = async (vehicleId) => {
    try {
      const messageId = messages.find(({ carouselItems }) =>
        carouselItems?.find(({ vehicleid }) => vehicleId === vehicleid)
      ).id;
      const numericMessageId = parseInt(messageId, 10);
      if (isNaN(numericMessageId)) {
        throw new Error("Invalid message ID");
      }
      const response = await postWhislistApi(numericMessageId, vehicleId);
      if (response) {
        if (wishlistItems?.some(({ vehicleid: vId }) => vehicleId === vId)) {
          setWishlistItems((wishlistItems) =>
            wishlistItems?.filter(({ vehicleid: vId }) => vehicleId !== vId)
          );
        } else {
          const vehiclesInfo = getAllVehicleInfosFromMessages();
          const vehicleInfo = vehiclesInfo.find(
            ({ vehicleid }) => vehicleid === vehicleId
          );
          setWishlistItems((wishlistItems) => [...wishlistItems, vehicleInfo]);
        }
      } else {
        throw new Error("Failed to update wishlist");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      setError("Failed to update wishlist. Please try again.");
    }
  };

  const handleIncomingMessage = useCallback((data) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      let currentMessage;
  
      // Handle predicted questions as a separate case
      if (data.type === "predicted_questions") {
        currentMessage = {
          sender: "server",
          type: "predicted_questions",
          questions: data.questions,
          id: data.id || `pred-${Date.now()}`,
          loading: false
        };
        newMessages.push(currentMessage);
        return newMessages;
      }
  
      if (data.type === "stream_message") {
        if (data.status === "tool") {
          const toolName = data.message;
          const lastMessage = newMessages[newMessages.length - 1] || {};
          const updatedToolList = Array.isArray(lastMessage.tool)
            ? [...new Set([...lastMessage.tool, toolName])]
            : [toolName];
          currentMessage = {
            ...lastMessage,
            tool: updatedToolList,
            loading: true,
            status: data.status,
          };
          if (newMessages.length > 0) {
            newMessages[newMessages.length - 1] = currentMessage;
          } else {
            newMessages.push(currentMessage);
          }
        } else {
          if (currentMessageRef.current) {
            currentMessage = {
              ...currentMessageRef.current,
              text: currentMessageRef.current.text + data.message,
              loading: true,
            };
          } else {
            currentMessage = {
              sender: "server",
              text: data.message,
              tool: [],
              id: data.id,
              loading: true,
            };
            newMessages.push(currentMessage);
          }
          currentMessageRef.current = currentMessage;
        }
      } else if (
        data.type === "message_complete" ||
        data.type === "admin_message"
      ) {
        setIsLoading(false);
        const lastMessage = newMessages[newMessages.length - 1] || {};
        currentMessage = {
          ...lastMessage,
          text: data.message,
          loading: false,
          status: undefined,
          tool: data.tool || [],
          id: data.id,
        };
  
        //if (data.type === "message_complete" && data.tool === "customer_registration_no_form" && data.first_name) {
        //  console.log("customer_registration_no_form", data);
        //}
  
        if (
          data.type === "message_complete" && 
          data.tool === "customer_registration"
        ) {
          hasTrackedFormShown.current = false;
          requestAnimationFrame(trackFormShown);
        }
  
        if (!currentMessage.id) {
          console.error(
            "Server did not provide an ID for the complete message"
          );
        }
  
        if (data.sources && Array.isArray(data.sources)) {
          if (data.tool && data.tool.includes("car_stock_search")) {
            try {
              currentMessage.carouselItems = mapItems(
                data.sources,
                propertyMappings[data.tool]
              );

              // Only track carousel if message is complete
              if (data.type === "message_complete" || data.type === "admin_message") {
                // Create a unique identifier for the carousel
                const carouselId = data.sources.map(item => item.vehicleid).join(',');

                // Only log if this exact carousel hasn't been logged before
                if (!loggedCarousels.has(carouselId)) {
                  const vehicleInfo = data.sources.map(item => ({
                    id: item.vehicleid,
                    title: item.description,
                    brand: item.brand,
                    usagetype: item.usagetype,
                    km: item.km
                  }));

                  trackChatEvent.carouselShown(data.sources.length, vehicleInfo);
                  loggedCarousels.add(carouselId);
                }
              }

              hasTrackedRef.current = true;
            } catch (mappingError) {
              console.warn("Error mapping items:", mappingError);
            }
          } else {
            currentMessage.carouselItems = data.sources;
          }
        }
        currentMessage.whishlist = data.whishlist || "";
  
        if (newMessages.length > 0 && data.type !== "admin_message") {
          newMessages[newMessages.length - 1] = currentMessage;
        } else if (data.type === "admin_message") {
          newMessages.push(currentMessage);
        } else {
          newMessages.push(currentMessage);
        }
        currentMessageRef.current = null;
      }
      return newMessages;
    });
  }, [trackFormShown]);

  const handleSend = async (message, file, audio, personaldata) => {
    setIsLoading(true);
    hasTrackedRef.current = false;
    if (!isAuthenticated) {
      setError("Not authenticated. Please try again.");
      return;
    }

    setError(null);
    let localUrl = null;
    let userMessage = {
      sender: "user",
      text: message || "Ti ho inviato un messaggio",
    };

    if (file) {
      localUrl = URL.createObjectURL(file);
      userMessage.file_url = localUrl;
    } else if (audio) {
      localUrl = URL.createObjectURL(audio);
      userMessage.file_url = localUrl;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      { sender: "server", text: "", loading: true },
    ]);
    lastUserMessage.current = message;
    currentMessageRef.current = { sender: "server", text: "", loading: true };

    const data = {
      type: file ? "file_message" : audio ? "audio_message" : "chat_message",
      input: message,
      file: file ? await fileToBase64(file) : null,
      file_name: file ? file.name : null,
      file_type: file ? file.type : null,
      audio: audio ? await fileToBase64(audio) : null,
      personaldata: personaldata,
      sender_url: window.location.href,//window.location.href, 
      ...getPersonalDataFromLocalStorage(),
    };

    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      websocketRef.current.send(JSON.stringify(data));
    } else {
      setError("WebSocket is not connected. Attempting to reconnect...");
      setIsLoading(false);
      attemptReconnect();
    }
  };

  const handleResendLastMessage = () => {
    if (lastUserMessage.current) {
      handleSend(lastUserMessage.current);
    }
  };

  const getPersonalDataFromLocalStorage = () => ({
    first_name:
      localStorage.getItem("aitomotivelab_personalData_first_name") || "",
    last_name:
      localStorage.getItem("aitomotivelab_personalData_last_name") || "",
    email: localStorage.getItem("aitomotivelab_personalData_email") || "",
    gdpr_consent:
      localStorage.getItem("aitomotivelab_personalData_gdpr") === "gdpr",
    marketing_consent:
      localStorage.getItem("aitomotivelab_personalData_marketing") ===
      "marketing",
    phone: localStorage.getItem("aitomotivelab_personalData_phone") || "",
    api_token: localStorage.getItem("api_token") || "",
  });

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Reset tracking for new conversations
  const resetFormTracking = useCallback(() => {
    hasTrackedFormShown.current = false;
    hasTrackedRef.current = false;
    formDisplayCount.current = 0;
    lastTrackingTimestamp.current = 0;
    localStorage.removeItem('form_display_count');
  }, []);

  return {
    isAuthenticated,
    isConnected,
    messages,
    isLoading,
    setIsLoading,
    wishlistItems,
    error,
    isReconnecting,
    reconnectCountdown,
    reconnectAttempts: reconnectAttempts.current,
    MAX_RECONNECT_ATTEMPTS,
    handleManualReconnect,
    handleSend,
    handleResendLastMessage,
    handleWishlistUpdate,
    isHumanControlled,
    humanControlMessage,
    colors,
    accessToken,
    resetFormTracking,
  };
};

export default useChatWebSocket;
