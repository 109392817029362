import {
  Box,
  Fade,
  Grow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

import ChatHeader from "./ChatHeader";
import ToggleChat from "./ToggleChat";
import ChatBody from "./ChatBody/index.jsx";
import ChatFooter from "./ChatFooter";
import ConnectionStatus from "./ConnectionStatus";
import useChatWebSocket from "../api/websocket";
import CustomScrollbar from './CustomScrollbar';

const ChatWrapper = () => {
  const [isChatVisible, setChatVisibility] = useState(false);
  const [isChatWide, setChatWide] = useState(false);
  const [showReconnectionUI, setShowReconnectionUI] = useState(false);

  const {
    isAuthenticated,
    messages,
    isLoading,
    setIsLoading,
    wishlistItems,
    error,
    isReconnecting,
    reconnectCountdown,
    reconnectAttempts,
    MAX_RECONNECT_ATTEMPTS,
    handleManualReconnect,
    handleSend,
    handleResendLastMessage,
    handleWishlistUpdate,
    isHumanControlled,
    humanControlMessage,
    isConnected,
    accessToken
  } = useChatWebSocket();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleChat = () => setChatVisibility(!isChatVisible);
  const toggleChatWidth = () => setChatWide(!isChatWide);

  // Lock body scroll when chat is visible on mobile
  useEffect(() => {
    if (isMobile && isChatVisible) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isMobile, isChatVisible]);

  useEffect(() => {
    let timer;
    if (!isConnected) {
      timer = setTimeout(() => {
        setShowReconnectionUI(true);
      }, 3000);
    } else {
      setShowReconnectionUI(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isConnected]);

  useEffect(() => {
    if (isHumanControlled && isLoading) {
      setIsLoading(false);
    }
  }, [isHumanControlled, isLoading, setIsLoading]);

  return (
    <AnimatePresence>
      {isChatVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
          style={{
            position: "fixed",
            maxWidth: "100%",
            bottom: isMobile || isChatWide ? 0 : "2vh",
            right: isMobile || isChatWide ? 0 : "2vw",
            left: isMobile || isChatWide ? 0 : "auto",
            width: isMobile ? "100vw" : "auto",
            height: isMobile ? "100%" : "auto",
            color: "black",
            isolation: "isolate",
            pointerEvents: 'auto',
            zIndex: 1000,
            ...(isMobile && {
              touchAction: 'none',
              WebkitOverflowScrolling: 'touch',
            }),
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : isChatWide ? "100vw" : "50vw",
              height: isMobile ? "100%" : isChatWide ? "100vh" : "80vh",
              maxWidth: "100%",
              minWidth: isMobile ? "100%" : "50vw",
              minHeight: isMobile ? "100%" : "80vh",
              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
              borderRadius: isMobile ? 0 : "20px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              transition: "all 0.3s ease-in-out",
              backgroundColor: theme.palette.v2.chatBackground,
              position: "relative",
              ...(isMobile && {
                touchAction: 'manipulation',
                WebkitOverflowScrolling: 'touch',
              }),
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 10,
                backgroundColor: theme.palette.v2.chatBackground,
                userSelect: "none",
                cursor: "default",
              }}
            >
              <ChatHeader
                isMobile={isMobile}
                toggleChat={toggleChat}
                toggleChatWidth={toggleChatWidth}
                isChatWide={isChatWide}
                isLoading={isLoading}
                wishlistItems={wishlistItems}
                onWishlistUpdate={handleWishlistUpdate}
                onSendMessage={(message) => handleSend(message)}
                isHumanControlled={isHumanControlled}
                humanControlMessage={humanControlMessage}
              />
            </Box>

            <CustomScrollbar>
              <Box
                sx={{
                  position: "relative",
                  flexGrow: 1,
                  p: 0,
                  display: "flex",
                  flexDirection: "column",
                  ...(isMobile && {
                    touchAction: 'pan-y',
                    WebkitOverflowScrolling: 'touch',
                    overscrollBehavior: 'contain',
                  }),
                }}
                onClick={(e) => {
                  if (isMobile) {
                    e.stopPropagation();
                  }
                }}
              >
                <Fade in={isConnected} timeout={500}>
                  <Box
                    style={{
                      display: isConnected ? "block" : "none",
                      height: "100%",
                      ...(isMobile && {
                        touchAction: 'pan-y',
                        WebkitOverflowScrolling: 'touch',
                      }),
                    }}
                  >
                    <ChatBody
                      messages={messages}
                      isLoading={isLoading}
                      onSendMessage={handleSend}
                      onSendFile={(file, message) => handleSend(message, file)}
                      onSendPersonalData={(personaldata) =>
                        handleSend(
                          "Dati personali salvati",
                          null,
                          null,
                          personaldata
                        )
                      }
                      onWishlistUpdate={handleWishlistUpdate}
                      isConnected={isConnected}
                      isAuthenticated={isAuthenticated}
                      isHumanControlled={isHumanControlled}
                      setIsLoading={setIsLoading}
                      wishlistItems={wishlistItems}
                      accessToken={accessToken}
                    />
                  </Box>
                </Fade>

                <Fade in={!isConnected && showReconnectionUI} timeout={500}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.95)",
                      backdropFilter: "blur(5px)",
                    }}
                  >
                    <Grow in={!isConnected && showReconnectionUI} timeout={800}>
                      <Typography
                        variant="h5"
                        sx={{
                          mb: 2,
                          fontWeight: "bold",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {isReconnecting
                          ? "Riconnessione in corso..."
                          : "Connessione persa"}
                      </Typography>
                    </Grow>
                    <ConnectionStatus
                      isConnected={isConnected}
                      isReconnecting={isReconnecting}
                      reconnectCountdown={reconnectCountdown}
                      reconnectAttempts={reconnectAttempts}
                      MAX_RECONNECT_ATTEMPTS={MAX_RECONNECT_ATTEMPTS}
                      onManualReconnect={handleManualReconnect}
                    />
                  </Box>
                </Fade>
              </Box>
            </CustomScrollbar>

            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                zIndex: 10,
                backgroundColor: theme.palette.v2.chatBackground,
                userSelect: "none",
                cursor: "default",
              }}
            >
              <Fade in={isConnected} timeout={500}>
                <div style={{ display: isConnected ? "block" : "none" }}>
                  <ChatFooter
                    onSendMessage={handleSend}
                    onSendFile={(file, message) => handleSend(message, file)}
                    onSendAudio={(audio, message) =>
                      handleSend(message, null, audio)
                    }
                    isLoading={isLoading}
                    wishlistItems={wishlistItems}
                    onWishlistUpdate={handleWishlistUpdate}
                    onResendLastMessage={handleResendLastMessage}
                    isReconnecting={isReconnecting}
                    disabled={isHumanControlled ? false : !isAuthenticated}
                    isHumanControlActive={isHumanControlled}
                  />
                </div>
              </Fade>
            </Box>
          </Box>
        </motion.div>
      )}
      {!isChatVisible && (
        <ToggleChat 
          isChatVisible={isChatVisible} 
          toggleChat={toggleChat} 
          onSendMessage={(message) => handleSend(message)}
        />
      )}
    </AnimatePresence>
  );
};

export default ChatWrapper;