import {
  SmartToy as AIIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  AttachFile as AttachIcon,
  ArrowBack as BackIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  FaCompressArrowsAlt,
  FaExpandArrowsAlt,
  FaHeart,
} from "react-icons/fa";
import styled from "styled-components";
import { lightenColor } from "../utils/colors";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import WishlistPopup from "./WishlistPopup";
import { trackChatEvent } from '../utils/analytics';

const HeaderContainer = styled(motion.div)`
  border-bottom: 1px solid ${(props) => props.theme.palette.borderColor};
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
`;

const StyledAppBar = styled.div`
  color: ${(props) => props.theme.palette.v2.textPrimary};
  background-color: ${(props) => props.theme.palette.v2.primary};
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(1, 2)};
`;

const AnimatedAvatar = styled(motion.create(Avatar))`
  width: 40px;
  height: 40px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.isOnline ? "#4CAF50" : "#FFA000")};
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid white;
`;

const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.v2.textPrimary};
`;

const ChatHeader = ({
  isMobile,
  toggleChat,
  toggleChatWidth,
  isChatWide,
  wishlistItems,
  onWishlistUpdate,
  onSendMessage,
  isLoading,
  isHumanControlled,
  humanControlMessage,
  isOnline,
}) => {
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const [typingEffect, setTypingEffect] = useState("");
  const [showControlMessage, setShowControlMessage] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const theme = useTheme();

  const messages = [
    <span>
      <img
        src="https://www.aitomotivelab.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLOGO_MIA.731a2d9a.png&w=1200&q=75"
        alt="MIA Logo"
        width="34"
        height="12"
        style={{ marginRight: "4px", verticalAlign: "middle" }}
      />
      può commettere errori, verifica le informazioni.
    </span>,
    <span>
      Fai click sull'icona <FaHeart style={{ verticalAlign: 'middle' }}/> per aggiungere l'auto ai preferiti!
    </span>,
    <span>
      Fai click sull'icona <AttachIcon style={{ verticalAlign: 'middle' }}/> e allega la foto dell'auto che ti piace per effettuare una ricerca per immagine!
    </span>,
    !isMobile && (
      <span>
        Fai click su <FaExpandArrowsAlt style={{ verticalAlign: 'middle' }}/> in alto a destra per espandere la chat
      </span>
    ),
    <span>
      Utilizzando la chat, accetti la nostra Privacy Policy.
    </span>,
  ].filter(Boolean);

  const handleWishlistToggle = () => {
    trackChatEvent.openWishlist();
    setIsWishlistOpen(!isWishlistOpen);
  };

  useEffect(() => {
    setShowControlMessage(true);
    
    if (isHumanControlled) {
      setTypingEffect(humanControlMessage || "Un operatore è ora disponibile.");
      return;
    }

    const interval = setInterval(() => {
      setMessageIndex((prev) => (prev + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [isHumanControlled, humanControlMessage, messages.length]);

  useEffect(() => {
    if (!isHumanControlled) {
      setTypingEffect(messages[messageIndex]);
    }
  }, [messageIndex, isHumanControlled]);

  return (
    <StyledComponentsThemWrapper>
      <HeaderContainer
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <StyledAppBar position="static" elevation={3}>
          <StyledToolbar>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StyledIconButton 
                onClick={() => {
                  trackChatEvent.toggleChat(false);
                  toggleChat();
                }}
              >
                <BackIcon sx={{ color: theme.palette.v2.textPrimary }} />
              </StyledIconButton>
              <AnimatedAvatar
                src={localStorage.getItem("logo")}
                alt="Chat Logo"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <StatusIndicator isOnline={isOnline} />
              </AnimatedAvatar>
              <Box>
                <Typography variant="h6" color={theme.palette.v2.textPrimary}>
                  {localStorage.getItem("name_chat")}
                </Typography>
                <Typography variant="caption">
                  {isHumanControlled ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PersonIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Operatore online
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AIIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Assistente AI
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AnimatePresence>
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <Badge
                    badgeContent={wishlistItems.length > 0 ? wishlistItems.length : null}
                    color="secondary"
                  >
                    <StyledIconButton
                      aria-label="wishlist"
                      onClick={handleWishlistToggle}
                      disabled={isLoading}
                    >
                      <FaHeart style={{ color: theme.palette.v2.textPrimary }} />
                    </StyledIconButton>
                  </Badge>
                </motion.div>
              </AnimatePresence>
              {!isMobile && (
                <StyledIconButton 
                  onClick={() => {
                    trackChatEvent.toggleChatSize(isChatWide ? 'small_size' : 'large_size');
                    toggleChatWidth();
                  }}
                >
                  {isChatWide ? 
                    <FaCompressArrowsAlt style={{ color: theme.palette.v2.textPrimary }} /> : 
                    <FaExpandArrowsAlt style={{ color: theme.palette.v2.textPrimary }} />
                  }
                </StyledIconButton>
              )}
            </Box>
          </StyledToolbar>
        </StyledAppBar>
        <AnimatePresence mode="wait">
          {showControlMessage && (
            <Box
              sx={{
                padding: theme.spacing(1, 2),
                backgroundColor: isHumanControlled
                  ? "rgba(76, 175, 80, 0.1)"
                  : lightenColor(theme.palette.primary.main, 28),
                textAlign: "center",
                overflow: "hidden",
                position: "relative",
                minHeight: "40px",
                maxHeight: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                whiteSpace: "normal",
                boxSizing: "border-box",
              }}
            >
              <motion.span
                key={messageIndex}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                style={{ position: "relative", width: "100%", textAlign: "center" }}
              >
                <Typography variant="body2" sx={{ fontStyle: "italic" }} noWrap={false}>
                  {typingEffect}
                </Typography>
              </motion.span>
            </Box>
          )}
        </AnimatePresence>
      </HeaderContainer>
      <WishlistPopup
        open={isWishlistOpen}
        handleClose={() => setIsWishlistOpen(false)}
        wishlistItems={wishlistItems}
        onWishlistUpdate={onWishlistUpdate}
        onSendMessage={onSendMessage}
      />
    </StyledComponentsThemWrapper>
  );
};

export default ChatHeader;
