import React from 'react';
import { MessageCircle, Search, ChevronRight, X, ChevronUp } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import styled, { keyframes, css } from "styled-components";
import { IconButton, Tooltip } from "@mui/material";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import { trackChatEvent } from '../utils/analytics';

const float = keyframes`
  0%, 100% { 
    transform: translateY(0px); 
  }
  50% { 
    transform: translateY(-3px); 
  }
`;

const pulse = keyframes`
  0% { 
    box-shadow: 0 0 0 0 rgba(37, 99, 235, 0.4); 
  }
  70% { 
    box-shadow: 0 0 0 10px rgba(37, 99, 235, 0); 
  }
  100% { 
    box-shadow: 0 0 0 0 rgba(37, 99, 235, 0); 
  }
`;

const shine = keyframes`
  0% { 
    background-position: -200% 50%; 
  }
  100% { 
    background-position: 200% 50%; 
  }
`;

const ToggleContainer = styled(motion.div)`
  position: fixed;
  bottom: clamp(20px, 4dvh, 40px);
  right: clamp(20px, 4dvw, 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
  width: clamp(250px, 35dvw, 400px);
  max-height: calc(100dvh - 40px);

  @media (max-width: 640px) {
    bottom: 80px;
    right: 20px;
    width: auto;
    max-width: 300px;
    transform-origin: bottom right;
    z-index: 998;
  }
`;

const SearchIconStyled = styled(Search)`
  color: ${props => props.theme.palette.v2.textPrimary};
  opacity: 0.8;
  min-width: 24px;
  width: 24px;
  height: 24px;

  @media (max-width: 640px) {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
`;

const SearchBarContainer = styled(motion.div)`
  background: ${props => props.theme.palette.v2.primary};
  padding: 16px 20px;
  border-radius: 20px;
  margin-bottom: 8px;
  box-shadow: 
    0 10px 40px -10px ${props => `${props.theme.palette.action.shadowLight}`},
    0 0 20px -5px ${props => `${props.theme.palette.action.shadow}`};
  position: relative;
  cursor: text;
  display: flex;
  align-items: center;
  backdrop-filter: blur(10px);
  border: 1px solid ${props => props.theme.palette.divider};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    ${props => props.theme.palette.primary.light},
    ${props => props.theme.palette.primary.main},
    ${props => props.theme.palette.primary.light}
  );
  background-size: 200% auto;
  ${css`animation: ${shine} 4s linear infinite;`}

  &:hover {
    transform: translateY(-3px);
    box-shadow: 
      0 25px 65px -15px ${props => `${props.theme.palette.action.shadow}`},
      0 0 35px -5px ${props => `${props.theme.palette.action.shadowLight}`};
    border-color: ${props => props.theme.palette.primary.main}60;
  }

  &:hover ${SearchIconStyled} {
    color: ${props => props.theme.palette.primary.main};
    opacity: 1;
  }

  @media (max-width: 640px) {
    display: none;
  }
`;

const SearchInput = styled.input`
  border: none;
  border-radius: 25px;
  outline: none;
  background: transparent;
  color: ${props => props.theme.palette.v2.textPrimary};
  font-size: 16px;
  flex: 1;
  margin-left: 8px;
  padding: 8px 16px;
  width: 100%;
  transition: all 0.2s ease;

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: transparent;
  }

  @media (max-width: 640px) {
    font-size: 16px;
    padding: 8px 12px;
    margin-left: 8px;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  flex: 1;
`;

const PlaceholderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.palette.v2.textPrimary};
  opacity: 0.8;
  pointer-events: none;
  display: flex;
  align-items: center;
  ${props => props.isHidden && 'display: none;'};
  white-space: nowrap;

  @media (max-width: 640px) {
    font-size: 14px;
    transform: translateY(-50%) scale(0.9);
    transform-origin: left center;
  }
`;

const MiaLogo = styled.img`
  height: 12px;
  width: 34px;
  vertical-align: middle;
  margin: 0 4px;

  @media (max-width: 640px) {
    height: 14px;
    width: 40px;
    margin: 0 4px;
  }
`;

const HideOptionsButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
  z-index: 1001;
  padding: 0;
  
  &:hover {
    background: ${props => props.theme.palette.action.hover};
  }
`;

const QuickSuggestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
  
  @media (max-width: 640px) {
    position: fixed;
    bottom: 140px;
    right: 20px;
    width: 180px;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;
    gap: 4px;
    z-index: 997;
    background: transparent;
    
    > * {
      pointer-events: auto;
    }
  }
`;

const SuggestionChip = styled(motion.button)`
  width: 100%;
  background: ${props => props.theme.palette.background.paper};
  color: ${props => props.theme.palette.primary.main};
  border: 1px solid ${props => props.theme.palette.divider};
  padding: 16px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 0;

  @media (max-width: 640px) {
    background: ${props => props.theme.palette.background.paper};
    width: 180px;
    min-width: 180px;
    padding: 8px 12px;
    font-size: 13px;
    border-radius: 12px;
    white-space: nowrap;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    
    .suggestion-arrow {
      display: none;
    }
  }
`;

const SuggestionArrow = styled(ChevronRight)`
  width: 16px;
  height: 16px;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateX(-4px);

  @media (max-width: 640px) {
    width: 16px;
    height: 16px;
    opacity: 0;
    transform: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  background: ${props => props.theme.palette.primary.main} !important;
  color: ${props => props.theme.palette.primary.contrastText} !important;
  width: 64px !important;
  height: 64px !important;
  box-shadow: 0 8px 32px ${props => `${props.theme.palette.action.shadowLight}`} !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
  ${css`animation: ${pulse} 3s infinite;`}

  &:hover {
    transform: scale(1.2) !important;
    background: ${props => props.theme.palette.primary.dark} !important;
    box-shadow: 
      0 16px 56px ${props => `${props.theme.palette.action.shadow}`},
      0 0 0 12px ${props => `${props.theme.palette.primary.main}15`} !important;
  }

  @media (max-width: 640px) {
    width: 40px !important;
    height: 40px !important;
    
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const SubFormContainer = styled(motion.div)`
  background: ${props => props.theme.palette.background.paper};
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 12px;
  border: 1px solid ${props => props.theme.palette.divider};
  width: 100%;
  box-shadow: 0 10px 40px -10px ${props => `${props.theme.palette.action.shadowLight}`};
  backdrop-filter: blur(10px);

  @media (max-width: 640px) {
    position: fixed;
    bottom: 140px;
    right: 20px;
    width: 300px;
    padding: 20px;
    border-radius: 20px;
    z-index: 997;
  }
`;

const FormTitle = styled.h3`
  margin: 0 0 20px 0;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.palette.primary.main};
  text-align: center;
  font-family: inherit;

  @media (max-width: 640px) {
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const FormInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${props => props.theme.palette.divider};
  margin-bottom: 8px;
  background: ${props => props.theme.palette.background.paper};
  color: ${props => props.theme.palette.text.primary};
  font-size: 14px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover, &:focus {
    border-color: ${props => props.theme.palette.primary.main};
    outline: none;
  }
`;

const BrandInput = styled(FormInput)`
  border-radius: 12px;
  margin-bottom: 12px;
  height: 48px;
  padding: 8px 16px;
  font-size: 16px;
  border: 2px solid ${props => props.theme.palette.divider};
  transition: all 0.2s ease;

  &:hover, &:focus {
    border-color: ${props => props.theme.palette.primary.main};
    box-shadow: 0 0 0 4px ${props => `${props.theme.palette.primary.main}15`};
  }

  @media (max-width: 640px) {
    height: 48px;
    font-size: 16px;
    border-radius: 12px;
    padding: 8px 16px;
    margin-bottom: 12px;

    &::placeholder {
      font-size: 16px;
    }
  }
`;

const Select = styled.select`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border-radius: 12px;
  border: 2px solid ${props => props.theme.palette.divider};
  margin-bottom: 20px;
  background: ${props => props.theme.palette.background.default};
  color: ${props => props.theme.palette.text.primary};
  font-size: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;

  &:hover, &:focus {
    border-color: ${props => props.theme.palette.primary.main};
    box-shadow: 0 0 0 4px ${props => `${props.theme.palette.primary.main}15`};
  }

  @media (max-width: 640px) {
    height: 48px;
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 14px;
  background: ${props => props.theme.palette.primary.main};
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 16px;
  font-weight: 600;
  font-family: inherit;
  position: relative;
  overflow: hidden;

  &:hover {
    background: ${props => props.theme.palette.primary.dark};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px ${props => `${props.theme.palette.primary.main}40`};
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 640px) {
    padding: 16px;
    font-size: 16px;
    border-radius: 12px;
  }
`;

const DateTimeInput = styled(FormInput)`
  border-radius: 8px;
  width: calc(100% - 32px);
  margin: 0 16px 8px 16px;
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: ${props => props.theme.palette.mode === 'dark' ? 'invert(1)' : 'none'};
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
    padding: 12px;
    font-size: 16px;
  }
`;

const TimeSelect = styled(FormInput).attrs({ as: 'select' })`
  border-radius: 100px;
  width: calc(100% - 32px);
  margin: 0 16px 8px 16px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;
  padding-right: 42px;

  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
    padding: 12px;
    font-size: 16px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px !important;
  background: transparent !important;
  color: ${props => props.theme.palette.text.secondary} !important;
  
  &:hover {
    background: ${props => props.theme.palette.action.hover} !important;
    color: ${props => props.theme.palette.text.primary} !important;
  }

  @media (max-width: 640px) {
    display: none !important;
  }
`;

const BrandInputContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
  width: 100%;
  margin-right: 0;

  @media (max-width: 640px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 8px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  
  @media (max-height: 600px) {
    max-height: 150px;
  }

  @media (max-height: 400px) {
    max-height: 100px;
  }

  @media (max-width: 640px) {
    border-radius: 8px;
    margin-top: 4px;
    max-height: 200px;
    border-width: 1px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const SuggestionItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.palette.action.hover};
    color: ${props => props.theme.palette.primary.main};
  }

  ${props => props.selected && `
    background: ${props.theme.palette.action.selected};
    color: ${props.theme.palette.primary.main};
  `}

  @media (max-width: 640px) {
    padding: 8px 12px;
    font-size: 14px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    min-height: 40px;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    &:active {
      background: ${props => props.theme.palette.action.selected};
    }
  }
`;

const brandSuggestions = [
  // Volkswagen Group Brands
  // Volume Brands
  'Volkswagen', 'Volkswagen Commercial Vehicles', 'VW', 'VW Commercial',
  'ŠKODA', 'SEAT', 'CUPRA',
  
  // Premium/Sport Brands
  'Audi', 'Audi Sport', 'Audi RS', 
  'Porsche', 'Porsche Motorsport',
  'Lamborghini',
  
  // Super Luxury Brands
  'Bentley', 
  'Bugatti', // Until 2021
  
  // Historical VW Group Brands
  'Auto Union', 
  'DKW', 
  'Horch', 
  'NSU', 
  'Wanderer',
  
  // Truck Brands
  'MAN', 
  'Scania', 
  'Navistar International',
  
  // Motorcycle Brand
  'Ducati',

  // Sub-brands and Performance Divisions
  'Audi Sport GmbH',
  'quattro GmbH',
  'Volkswagen R',
  'SEAT Sport',
  'ŠKODA vRS',
  'Porsche Exclusive Manufaktur',

  // Premium/Luxury Brands
  'Mercedes-Benz', 'Mercedes-AMG', 'Mercedes-Maybach', 'Lexus', 
  'Land Rover', 'Range Rover', 'Jaguar', 'Volvo', 'Genesis', 'Infiniti', 'Acura',

  // Italian Brands
  'Alfa Romeo', 'Ferrari', 'Fiat', 'Maserati', 'Lancia', 'Abarth', 
  'Autobianchi', 'De Tomaso', 'Innocenti', 'Bizzarrini', 'Fornasari', 'Ermini',

  // German Brands
  'BMW', 'Opel', 'MINI', 'Alpina', 'Artega', 'Gumpert', 'Isdera', 'Mansory', 
  'Maybach', 'Melkus', 'Ruf', 'Wiesmann', 'Brabus', 'BMW M', 'BMW i',

  // French Brands
  'Peugeot', 'Renault', 'Alpine', 'Citroën', 'DS Automobiles', 'Delage',
  'Facel Vega', 'Gordini', 'Panhard', 'Talbot', 'Venturi', 'De Dion-Bouton',

  // Asian Brands
  // Japanese
  'Toyota', 'Lexus', 'Honda', 'Acura', 'Mazda', 'Nissan', 'NISMO', 'Infiniti', 
  'Suzuki', 'Subaru', 'Mitsubishi', 'Daihatsu', 'Scion', 'Isuzu', 'Hino',
  // Korean
  'Hyundai', 'Kia', 'Genesis', 'SsangYong', 'Daewoo',
  // Chinese
  'BYD', 'Geely', 'Great Wall', 'Haval', 'NIO', 'Xpeng', 'Li Auto', 'Lynk & Co',
  'MG', 'Hongqi', 'Wuling', 'Zeekr', 'Ora', 'Voyah', 'Weltmeister',
  // Other Asian
  'Proton', 'Perodua', 'Tata', 'Mahindra', 'Maruti Suzuki', 'VinFast',

  // American Brands
  'Ford', 'Lincoln', 'Chevrolet', 'GMC', 'Cadillac', 'Jeep', 'Chrysler', 'Dodge', 
  'RAM', 'Tesla', 'Rivian', 'Lucid', 'Fisker', 'Karma', 'Bollinger', 'Canoo',
  'Hudson', 'Packard', 'Pontiac', 'Oldsmobile', 'Plymouth', 'DeSoto', 'Tucker',
  'Saturn', 'Mercury', 'Hummer', 'Eagle', 'Imperial', 'DeLorean', 'Stutz',

  // British Brands
  'Rolls-Royce', 'Aston Martin', 'McLaren', 'MINI', 'MG', 'Lotus',
  'Morgan', 'TVR', 'Caterham', 'Noble', 'Bristol', 'Jensen', 'Triumph', 'Austin',
  'Morris', 'Vauxhall', 'Riley', 'Sunbeam', 'Rover', 'BAC', 'Radical', 'Ariel',

  // Swedish Brands
  'Volvo', 'Polestar', 'Koenigsegg', 'Saab',

  // Spanish Brands
  'Hispano-Suiza', 'Pegaso', 'GTA Motor', 'Tramontana',

  // Romanian Brands
  'Dacia',

  // Other European Brands
  // Dutch
  'Spyker', 'Donkervoort', 'DAF', 'Vandenbrink',
  // Croatian
  'Rimac', 'DOK-ING',
  // Austrian
  'KTM', 'Magna Steyr',
  // Swiss
  'Rinspeed', 'Monteverdi', 'Sbarro',
  // Danish
  'Zenvo',
  // Belgian
  'Minerva', 'Gillet',
  // Russian
  'Lada', 'UAZ', 'GAZ', 'ZIL', 'Moskvitch', 'Aurus',

  // Luxury/Performance/Limited Production
  'Pagani', 'W Motors', 'Pininfarina', 'Gordon Murray',
  'Hennessey', 'Czinger', 'SCG (Glickenhaus)', 'Apollo', 'Dallara', 'Ginetta',
  'Zenvo', 'Hispano Suiza', 'Isdera', 'Lykan', 'Vector', 'Arrinera',

  // Electric Vehicle Startups
  'Arrival', 'Faraday Future', 'Lordstown', 'Nikola', 'Workhorse', 'Lightning',
  'Sono Motors', 'Uniti', 'Aiways', 'Byton', 'Hozon', 'Leapmotor', 'WM Motor',
  'Lightyear', 'Piëch', 'Togg'
].sort();

const uniqueBrandSuggestions = [...new Set(brandSuggestions)];

const MobileMiniBubble = styled(motion.button)`
  display: none;
  
  @media (max-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.palette.primary.main};
    border-radius: 50%;
    width: 56px;
    height: 56px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 998;
    
    &:active {
      transform: scale(0.95);
    }
    ${props => props.disabled && css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
  }
`;

const MobileQuickActions = styled(motion.div)`
  display: none;
  
  @media (max-width: 640px) {
    display: flex;
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 8px;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
  }
`;

const QuickActionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 100px;
  padding: 8px 16px;
  font-size: 14px;
  gap: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  white-space: nowrap;
  color: ${props => props.theme.palette.text.primary};
  
  &:hover {
    background: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    border-color: transparent;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FormLabel = styled.label`
  font-size: 14px;
  color: ${props => props.theme.palette.text.secondary};
  margin-left: 4px;
`;

const FloatingMessage = styled(motion.div)`
  position: absolute;
  bottom: 100%;
  right: 10px;
  background: ${props => props.theme.palette.background.paper};
  border-radius: 16px 16px 16px 4px;
  padding: 10px 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 280px;
  min-width: 240px;
  border: 1px solid ${props => props.theme.palette.divider};
  display: flex;
  align-items: center;
  gap: 8px;
  pointer-events: none;
  z-index: 996;
  margin-bottom: 12px;
  cursor: pointer;
  pointer-events: auto;
  
  &:hover {
    transform: translateY(-2px);
    transition: transform 0.2s ease;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    right: 24px;
    width: 12px;
    height: 12px;
    background: ${props => props.theme.palette.background.paper};
    border-right: 1px solid ${props => props.theme.palette.divider};
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    transform: rotate(45deg);
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
  }

  @media (max-width: 640px) {
    bottom: 60px;
    right: 0;
    min-width: 220px;
    max-width: 260px;
    padding: 8px 14px;
    margin-bottom: 0;
    
    &::after {
      right: 20px;
      bottom: -8px;
    }
  }
  ${props => props.$hasError && css`
    background: ${props => props.theme.palette.error.light};
    border-color: ${props => props.theme.palette.error.main};
  `}
`;

const MessageText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.palette.text.primary};
  line-height: 1.3;
  white-space: normal;
`;

const MiaIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
`;

const ToggleChat = ({ isChatVisible, toggleChat, onSendMessage }) => {
  const [showSearch, setShowSearch] = React.useState(() => {
    return localStorage.getItem('hideSearchPrompt') !== 'true';
  });
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showConfigForm, setShowConfigForm] = React.useState(false);
  const [brand, setBrand] = React.useState('');
  const [budget, setBudget] = React.useState('');
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = React.useState(-1);
  const [showMobileActions, setShowMobileActions] = React.useState(true);
  const [hideOptions, setHideOptions] = React.useState(() => {
    return localStorage.getItem('hideQuickOptions') === 'true';
  });
  const [kilometers, setKilometers] = React.useState('');
  const [currentMessageIndex, setCurrentMessageIndex] = React.useState(0);
  const [connectionError, setConnectionError] = React.useState(false);
  const [retryCount, setRetryCount] = React.useState(0);
  const maxRetries = 3;
  const [showMessage, setShowMessage] = React.useState(true);

  const budgetRanges = [
    '< €20.000',
    '€20.000 - €35.000',
    '€35.000 - €50.000',
    '€50.000 - €75.000',
    '> €75.000'
  ];

  const kmRanges = [
    'Meno di 10.000 km',
    'Tra 10.000 e 20.000 km',
    'Tra 20.000 e 30.000 km',
    'Più di 30.000 km'
  ];

  const handleToggleClick = () => {
    if (!isChatVisible) {
      setShowSearch(false);
      setHideOptions(false);
      trackChatEvent.toggleChat(true);
    }
    toggleChat();
  };

  const handleSuggestion = (message) => {
    toggleChat();
    trackChatEvent.toggleChat(true);
    setTimeout(() => {
      onSendMessage(message);
    }, 300);
  };

  const handleConfigSubmit = (e) => {
    e.preventDefault();
    let message = "Vorrei cercare un'auto";
    
    const conditions = [];
    if (brand) {
      conditions.push(`marca ${brand}`);
      trackChatEvent.brandSelect(brand);
    }
    if (budget) conditions.push(`budget ${budget}`);
    if (kilometers) conditions.push(`percorro ${kilometers} all'anno`);
    
    if (conditions.length > 0) {
      message += ` con ${conditions.join(', ')}`;
    }
    
    handleSuggestion(message);
    setShowConfigForm(false);
    setBrand('');
    setBudget('');
    setKilometers('');
  };

  const suggestions = [
    { 
      text: "🚗 Cerca un'auto", 
      mobileText: "🚗 Cerca un'auto",
      onClick: () => {
        setShowConfigForm(prev => !prev);
        setHideOptions(true);
      }
    },
    { 
      text: "💰 Offerte attive", 
      mobileText: "💰 Offerte attive",
      message: "Quali sono le offerte di questo mese?" 
    },
    { 
      text: "🎯 Test drive", 
      mobileText: "🎯 Test drive",
      message: "Vorrei prenotare un test drive" 
    },
    { 
      text: "📅 Prenota un appuntamento", 
      mobileText: "📅 Prenota appuntamento",
      message: "Vorrei fissare un appuntamento in concessionaria"
    }
  ];

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (searchQuery.trim()) {
      handleSuggestion(searchQuery.trim());
      setSearchQuery('');
    }
  };

  const handleMinimize = (e) => {
    e.stopPropagation();
    trackChatEvent.minimizeChat();
    setIsMinimized(true);
    setShowSearch(false);
    setHideOptions(true);
    localStorage.setItem('hideSearchPrompt', 'true');
    localStorage.setItem('hideQuickOptions', 'true');
  };

  const isMobile = window.innerWidth <= 640;

  const handleBrandChange = (e) => {
    const value = e.target.value;
    setBrand(value);

    if (value.length > 0) {
      const filtered = uniqueBrandSuggestions.filter(
        suggestion => suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
      setSelectedSuggestionIndex(-1);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const getConfigButtonText = (brand, budget, km) => {
    const parts = [];
    if (brand) parts.push(brand);
    if (budget) parts.push(`${budget}`);
    if (km) parts.push(`${km}`);
    
    return parts.length > 0 
      ? `Cerca ${parts.join(' - ')}` 
      : "Cerca tutte le auto";
  };

  const handleMobileClick = (e) => {
    e.stopPropagation();
    if (e.target.closest('.toggle-arrow')) {
      setShowMobileActions(prev => !prev);
    } else {
      setIsMinimized(false);
      handleToggleClick();
    }
  };

  const handleHideOptions = (e) => {
    e.stopPropagation();
    setHideOptions(true);
    localStorage.setItem('hideQuickOptions', 'true');
  };

  React.useEffect(() => {
    if (!isChatVisible) {
      setShowSearch(false);
      const hideQuickOptions = localStorage.getItem('hideQuickOptions') === 'true';
      setHideOptions(hideQuickOptions);
    }
  }, [isChatVisible]);

  React.useEffect(() => {
    const relevantIndices = getRelevantMessages();
    const randomIndex = relevantIndices[Math.floor(Math.random() * relevantIndices.length)];
    
    setCurrentMessageIndex(randomIndex);
    setShowMessage(true); // Show message on route change

    // Auto-hide after 10 seconds
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 10000);

    // Cleanup timer on unmount or route change
    return () => clearTimeout(timer);
  }, [hideOptions, window.location.pathname]);

  React.useEffect(() => {
    const handleWebSocketError = (error) => {
      console.warn('WebSocket connection error:', error);
      setConnectionError(true);
      
      if (retryCount < maxRetries) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
        }, 2000 * (retryCount + 1));
      }
    };

    window.addEventListener('websocketerror', handleWebSocketError);
    
    return () => {
      window.removeEventListener('websocketerror', handleWebSocketError);
    };
  }, [retryCount]);

  const getDisplayMessage = () => {
    if (connectionError) {
      return "Mi dispiace, sto avendo problemi di connessione. Riprova tra poco! 🔄";
    }
    return floatingMessages[currentMessageIndex];
  };

  // Define getRelevantMessages inside the component
  const getRelevantMessages = () => {
    const path = window.location.pathname.toLowerCase();
    
    // Get indices for relevant category or all messages if no specific path matches
    let messageIndices;
    
    if (path.includes('valuta')) {
      messageIndices = messageCategories.valuation;
    } else if (path.includes('promozioni') || path.includes('offerte')) {
      messageIndices = messageCategories.promotions;
    } else if (path.includes('appuntamento') || path.includes('prenota')) {
      messageIndices = messageCategories.appointment;
    } else {
      // If no specific path matches, use all message indices
      messageIndices = Array.from({ length: floatingMessages.length }, (_, i) => i);
    }
    
    return messageIndices;
  };

  const handleMessageClick = (message) => {
    if (message.onClick) {
      message.onClick();
    } else if (message.message) {
      trackChatEvent.toggleChat(true);
      toggleChat();
      setTimeout(() => {
        onSendMessage(message.message);
      }, 300);
      setShowMessage(false);
    }
  };

  // Move floatingMessages inside component to access state setters
  const floatingMessages = [
    // Car Search Messages
    {
      text: "Cerchi un'auto? Dimmi il tuo budget e ti aiuto a trovarla! 🚗",
      message: "Vorrei un consiglio per l'acquisto di un'auto",
      onClick: () => {
        setShowConfigForm(true);
        setHideOptions(true);
      }
    },
    {
      text: "Non sai quale modello scegliere? Parliamone insieme! 💭",
      message: "Non so quale modello scegliere, puoi aiutarmi?"
    },
    {
      text: "Ti aiuto a trovare l'auto perfetta per le tue esigenze! ✨",
      message: "Aiutami a trovare l'auto giusta per me"
    },
    
    // Appointment Messages
    {
      text: "Vuoi visitare la concessionaria? Prenota un appuntamento! 📅",
      message: "Vorrei fissare un appuntamento in concessionaria"
    },
    {
      text: "Interessato a un modello? Fissiamo un incontro in concessionaria! 🤝",
      message: "Vorrei fissare un appuntamento per vedere un modello specifico"
    },
    {
      text: "Prenota una visita e scopri le auto dal vivo! 🎯",
      message: "Vorrei prenotare una visita in concessionaria"
    },
    
    // Promotions Messages
    {
      text: "Scopri le offerte del mese sulle auto nuove! ⭐",
      message: "Quali sono le offerte di questo mese?"
    },
    {
      text: "Ti interessano le promozioni attive? Chiedimi! 💫",
      message: "Vorrei conoscere le promozioni attive"
    },
    {
      text: "Auto in pronta consegna a prezzi speciali, vuoi saperne di più? 🏷️",
      message: "Quali auto avete in pronta consegna?"
    }
  ];

  // Message categories should also be inside component
  const messageCategories = {
    search: [0, 1, 2],
    appointment: [3, 4, 5],
    promotions: [6, 7, 8]
  };

  React.useEffect(() => {
    const visitCount = parseInt(localStorage.getItem('visitCount') || '0', 10);
    const hasOpenedChat = localStorage.getItem('hasOpenedChat') === 'true';

    if (visitCount === 1 && !hasOpenedChat) {
      const timer = setTimeout(() => {
        if (!isChatVisible) {
          toggleChat();
          localStorage.setItem('hasOpenedChat', 'true'); // Save to localStorage
        }
      }, 15000); // 15 seconds

      const handleScroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const pageHeight = document.documentElement.scrollHeight;

        if (scrollPosition >= pageHeight / 2 && !isChatVisible) {
          toggleChat();
          localStorage.setItem('hasOpenedChat', 'true'); // Save to localStorage
          window.removeEventListener('scroll', handleScroll);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        clearTimeout(timer);
        window.removeEventListener('scroll', handleScroll);
      };
    }

    // Increment visit count
    localStorage.setItem('visitCount', (visitCount + 1).toString());
  }, [isChatVisible, toggleChat]);

  return (
    <StyledComponentsThemWrapper>
      <ToggleContainer>
        <AnimatePresence>
          {!isChatVisible && showSearch && !isMinimized && (
            <motion.div
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 20, scale: 0.95 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              style={{ width: '100%', position: 'relative' }}
            >
              <CloseButton
                onClick={handleMinimize}
                size="small"
                aria-label="close"
              >
                <X size={18} />
              </CloseButton>
              
              <SearchBarContainer hideOptions={hideOptions}>
                <SearchIconStyled size={24} />
                <form onSubmit={handleSearchSubmit} style={{ flex: 1 }}>
                  <SearchContainer>
                    <PlaceholderContainer isHidden={searchQuery.length > 0}>
                      Ciao sono <MiaLogo src="https://www.aitomotivelab.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLOGO_MIA.731a2d9a.png&w=1200&q=75" alt="MIA" /> l'AI che trova l'auto giusta per te
                    </PlaceholderContainer>
                    <SearchInput
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onFocus={() => setShowSearch(true)}
                      onClick={(e) => e.stopPropagation()}
                      aria-label="Campo di ricerca"
                    />
                  </SearchContainer>
                </form>
              </SearchBarContainer>
            </motion.div>
          )}

          {!isChatVisible && (
            <>
              {!hideOptions ? (
                <QuickSuggestions isHidden={!showMobileActions}>
                  <HideOptionsButton
                    onClick={handleHideOptions}
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                  >
                    <X size={14} />
                  </HideOptionsButton>
                  {suggestions.map((suggestion, index) => (
                    <SuggestionChip
                      key={index}
                      onClick={() => {
                        if (suggestion.onClick) {
                          suggestion.onClick();
                        } else {
                          handleSuggestion(suggestion.message);
                        }
                      }}
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      {suggestion.mobileText || suggestion.text}
                    </SuggestionChip>
                  ))}
                </QuickSuggestions>
              ) : showMessage && (
                <AnimatePresence mode="wait">
                  <FloatingMessage
                    initial={{ opacity: 0, y: 10, scale: 0.9 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, y: 10, scale: 0.9 }}
                    transition={{ duration: 0.3 }}
                    key={currentMessageIndex}
                    $hasError={connectionError}
                    onClick={() => {
                      handleMessageClick(floatingMessages[currentMessageIndex]);
                    }}
                  >
                    <MiaIcon 
                      src="https://www.aitomotivelab.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLOGO_MIA.731a2d9a.png&w=1200&q=75" 
                      alt="MIA"
                    />
                    <MessageText>
                      {floatingMessages[currentMessageIndex].text}
                    </MessageText>
                  </FloatingMessage>
                </AnimatePresence>
              )}
              
              <MobileMiniBubble
                onClick={handleMobileClick}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                disabled={connectionError}
              >
                <MessageCircle size={24} color="white" />
              </MobileMiniBubble>
            </>
          )}

          {!isChatVisible && showConfigForm && (
            <SubFormContainer
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 20, scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              <FormTitle>Cerca la tua auto ideale</FormTitle>
              <form onSubmit={handleConfigSubmit}>
                <FormGrid>
                  <FormGroup>
                    <FormLabel>Marca</FormLabel>
                    <BrandInputContainer>
                      <BrandInput
                        type="text"
                        placeholder="Inserisci la marca"
                        value={brand}
                        onChange={handleBrandChange}
                        autoComplete="off"
                      />
                      {showSuggestions && filteredSuggestions.length > 0 && (
                        <SuggestionsContainer>
                          {filteredSuggestions.map((suggestion, index) => (
                            <SuggestionItem
                              key={suggestion}
                              selected={index === selectedSuggestionIndex}
                              onClick={() => {
                                setBrand(suggestion);
                                setShowSuggestions(false);
                              }}
                            >
                              {suggestion}
                            </SuggestionItem>
                          ))}
                        </SuggestionsContainer>
                      )}
                    </BrandInputContainer>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Budget</FormLabel>
                    <Select
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                    >
                      <option value="">Seleziona il tuo budget</option>
                      {budgetRanges.map((range) => (
                        <option key={range} value={range}>
                          {range}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Quanti km percorri all'anno?</FormLabel>
                    <Select
                      value={kilometers}
                      onChange={(e) => setKilometers(e.target.value)}
                    >
                      <option value="">Seleziona il chilometraggio annuale</option>
                      {kmRanges.map((range) => (
                        <option key={range} value={range}>
                          {range}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>
                </FormGrid>

                <SubmitButton type="submit">
                  {getConfigButtonText(brand, budget, kilometers)}
                </SubmitButton>
              </form>
            </SubFormContainer>
          )}
        </AnimatePresence>

        <Tooltip 
          title={connectionError ? "Servizio temporaneamente non disponibile" : "Chiedi a MIA"} 
          placement="left"
        >
          <StyledIconButton
            aria-label="toggle chat"
            onClick={() => {
              if (!connectionError) {
                setIsMinimized(false);
                handleToggleClick();
              }
            }}
            size="large"
            disabled={connectionError}
            sx={{ 
              '@media (max-width: 640px)': { display: 'none' },
              opacity: connectionError ? 0.7 : 1,
            }}
          >
            <MessageCircle size={28} />
          </StyledIconButton>
        </Tooltip>
      </ToggleContainer>
    </StyledComponentsThemWrapper>
  );
};

export default ToggleChat;
